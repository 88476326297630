import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  FRAME_EVENT_TYPES,
  IFrameEventClose,
  IFrameEventComplete,
  IFrameEventError,
  IFrameEventFirstInteraction,
  IFrameEventReady,
  IFrameEventRouteChange,
} from '@digitaservice/utils';

/**
 * The Widget Service
 */
@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  /**
   * A Reference to the window.
   */
  private window: Window;

  /**
   * Constructor
   */
  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.window = this.document.defaultView;
  }

  /**
   * Initialize the Widget
   */
  initialize(isInWidget: boolean, widgetType: 'fixed' | 'auto' | null) {
    // if we are in a widget, and if the widget type is fixed, some special things need to occur.
    if (isInWidget && widgetType === 'fixed') {
      // to the document body
      this.document.body.style.display = 'flex';
      this.document.body.style.flexDirection = 'column';

      // to the global page
      const appNativeContainer = this.document.getElementById('app-native-container') as HTMLDivElement;
      appNativeContainer.style.flex = '1 1 100%';
    }
  }

  /**
   * Has first interaction occurred with the widget?
   */
  registerFirstInteraction(event: IFrameEventFirstInteraction, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Occurs when the Route Changes.
   */
  registerRouteChange(event: IFrameEventRouteChange, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Occurs when the app has been delared ready.
   */
  registerReady(event: IFrameEventReady, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Occurs when the app wants to scroll to the top.
   */
  applyScrollToTop(widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(FRAME_EVENT_TYPES.SCROLL_TO_TOP, undefined, widgetType);
  }

  /**
   * Occurs when the app has been delared complete.
   */
  registerComplete(event: IFrameEventComplete, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Occurs when the app has been delared closed.
   */
  registerClose(event: IFrameEventClose, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Occurs when a fata error happens in the app.
   */
  registerError(event: IFrameEventError, widgetType: 'fixed' | 'auto' | null) {
    this.emitWidgetEvent(event.type, event.data, widgetType);
  }

  /**
   * Dispatches events to the parent
   * @param type - the name of the event
   * @param data - any data object or undefined
   * @param widgetType - what type of widget is this?
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private emitWidgetEvent(type: string, data: any = undefined, widgetType: 'fixed' | 'auto' | null) {
    if (widgetType === 'fixed') {
      // when the type of widget is fixed, it means iframe-resizer is not being used,
      // this in turn means that a native post message is used to dispatch widget events.
      // console.log('POST MESSAGE WINDOW NATIVE');
      this.window?.parent?.postMessage(
        {
          data,
          isDigitaService: true,
          isPlugin: false,
          type,
        },
        '*',
      );
    } else {
      // in the iframe-resizer library, window.parentIFrame is provided by the
      // library at an unknown time. This means `onReady` might not get called.

      // to address this, we add a timeout to try the event again until the
      // system is available.

      if (this.window?.parentIFrame) {
        this.window.parentIFrame.sendMessage(
          {
            data,
            isDigitaService: true,
            isPlugin: false,
            type,
          },
          undefined,
        );
      } else {
        this.window.setTimeout(() => {
          this.emitWidgetEvent(type, data, widgetType);
        }, 100);
      }
    }
  }
}
