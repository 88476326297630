// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

// Keeps all environments uniform
import { IBuildEnvironment } from './IBuildEnvironment';

// TSAppVersion
import versions from './version';

export const environment: IBuildEnvironment = {
  production: false,
  useWithCredentials: true,
  showDevPanel: true,
  localDevelopment: true,
  skipLocationChange: true,
  environmentName: 'local',
  environmentVersions: versions,
};
