import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ShellBackgroundEntityModel } from './shell-background-entity/shell-background-entity.model';
import { ShellBackgroundModel } from './shell-background.model';

/**
 * The Default State
 */
function initialState(): ShellBackgroundModel {
  return {
    systems: [],
  };
}

/**
 * The Store used for a {@link ShellBackgroundComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class ShellBackgroundRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `shell-background`,
    },
    withProps<ShellBackgroundModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ShellBackgroundEntityModel | null) {
    // if the configuration is specifically undefined, nothing
    // changes on the background because it persists.
    if (configuration === undefined) {
      return;
    }

    const { systems } = this.store.getValue();

    // clone the existing systems
    let newSystems = cloneDeep(systems);

    // if the configuration is specifically null, all systems are disabled
    if (configuration === null) {
      newSystems = [];
    }

    // if there is a configuration, it is added to the systems
    if (configuration) {
      newSystems.push(configuration);
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.systems = newSystems;
      }),
    );
  }

  /**
   * Occurs once a background has successfully animated into place.
   */
  applySuccessfulBackgroundAddition() {
    const { systems } = this.store.getValue();

    // clone the existing systems
    const newSystems = cloneDeep(systems);

    // remove the first system
    if (newSystems?.length > 1) {
      newSystems.shift();
    }

    //update the store
    this.store.update(
      ElfWrite((state) => {
        state.systems = newSystems;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  systems$ = this.store.pipe(select((store) => store.systems));
}
