import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { DigitaServiceInputs } from 'src/app/app-global-callbacks';
import { ElfWrite } from 'src/app/util/ElfWrite';

interface AppConfigModel {
  config: DigitaServiceInputs;
}

/**
 * The Default State
 */
function initialState(): AppConfigModel {
  return {
    config: {},
  };
}

/**
 * Stores the App Configuration
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `app-config`,
    },
    withProps<AppConfigModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  initialize(configuration: Partial<DigitaServiceInputs> = {}) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.config = configuration;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The initialized configuration.
   */
  config$ = this.store.pipe(select((state) => state.config));
}
