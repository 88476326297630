import { Injectable, OnDestroy } from '@angular/core';
import { DigitaServiceError } from '@digitaservice/utils/@types';
import { createStore, select, withProps } from '@ngneat/elf';
import { delay, first, interval, map, of, switchMap } from 'rxjs';
import { IShellLayout } from 'src/app/api/modules/core/components/abstract/IShellLayout';
import { DEFAULT_LOADING_INDICATOR_DISPLAY_DELAY, DEFAULT_SHELL_LAYOUT } from 'src/app/app-constants';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { AppModel } from './application.model';

/**
 * The Default State
 */
function initialState(): AppModel {
  return {
    viewportWidth: 0,
    viewportHeight: 0,
    preventWindowUnloadDialog: false,
    isInWidget: false,
    widgetType: null,
    hasBeenDeclaredComplete: false,
    hasBeenDeclaredReady: false,
    hasFatalError: false,
    hasFirstInteraction: false,
    hasInitialized: false,
    hasBeenDeclaredClosed: false,
    fatalErrorData: undefined,
    amp: false,
    layout: DEFAULT_SHELL_LAYOUT,
    loadingIndicator: true,
    orientation: 'landscape',
    disableOutletGrow: false,
    showDevPanel: false,
  };
}

/**
 * The App Repository contains high level information.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class AppRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `app`,
    },
    withProps<AppModel>(initialState()),
  );

  getValue() {
    return this.store.getValue();
  }

  ////////////////////////////////////////////////////////////////////
  // SYSTEM
  ////////////////////////////////////////////////////////////////////

  applySystemConfiguration(amp: boolean, preventWindowUnloadDialog: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.amp = amp;
        state.preventWindowUnloadDialog = preventWindowUnloadDialog;
      }),
    );
  }

  applyDevPanel(showDevPanel = false) {
    if (showDevPanel) {
      this.store.update(
        ElfWrite((state) => {
          state.showDevPanel = showDevPanel;
        }),
      );
    }
  }

  ////////////////////////////////////////////////////////////////////
  // WIDGET
  ////////////////////////////////////////////////////////////////////

  applyWidgetConfiguration(isInWidget: boolean, widgetType: 'fixed' | 'auto' | null) {
    this.store.update(
      ElfWrite((state) => {
        state.isInWidget = isInWidget;
        state.widgetType = widgetType;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////

  applyFirstInteraction() {
    this.store.update(
      ElfWrite((state) => {
        state.hasFirstInteraction = true;
      }),
    );
  }

  applyReady() {
    this.store.update(
      ElfWrite((state) => {
        state.hasBeenDeclaredReady = true;
      }),
    );
  }

  applyComplete() {
    this.store.update(
      ElfWrite((state) => {
        state.hasBeenDeclaredComplete = true;
      }),
    );
  }

  applyClose() {
    this.store.update(
      ElfWrite((state) => {
        state.hasBeenDeclaredClosed = true;
      }),
    );
  }

  applyInitialized() {
    this.store.update(
      ElfWrite((state) => {
        state.hasInitialized = true;
      }),
    );
  }

  applyError(fatalErrorData: DigitaServiceError) {
    this.store.update(
      ElfWrite((state) => {
        state.hasFatalError = true;
        state.fatalErrorData = fatalErrorData;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // VIEWPORT RESIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Store the Viewport Size.
   *
   * @param width - the width of the viewport
   * @param height - the height of the viewport
   */
  applyViewportResize(width: number, height: number) {
    this.store.update(
      ElfWrite((state) => {
        state.viewportWidth = width;
        state.viewportHeight = height;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // ORIENTATION
  ////////////////////////////////////////////////////////////////////

  /**
   * The Applications Orientiation.
   *
   * @param orientation - the orientiation
   */
  applyOrientation(orientation: 'landscape' | 'portrait') {
    this.store.update(
      ElfWrite((state) => {
        state.orientation = orientation;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // SHELL LOADER
  ////////////////////////////////////////////////////////////////////

  /**
   * Show the Shell Loader or not.
   *
   * @param active - show the loader if true or hide if false
   */
  applyShellLoader(active: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.loadingIndicator = active;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////
  // SHELL LAYOUT
  ////////////////////////////////////////////////////////////////////

  /**
   * Set the layout of the application.
   *
   * @param layout - the shell layout
   * @param disableOutletGrow - disable the outlet grow
   */
  applyShellLayout(layout: Required<IShellLayout>, disableOutletGrow = false) {
    this.store.update(
      ElfWrite((state) => {
        state.layout = layout;
        state.disableOutletGrow = disableOutletGrow;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Should the Dev Panel be shown?
   */
  showDevPanel$ = this.store.pipe(select((state) => state.showDevPanel));

  ////////////////////////////////////////////////////////////////////
  // WIDGET
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Application running in a widget?
   */
  isInWidget$ = this.store.pipe(select((state) => state.isInWidget));

  /**
   * What type of widget is in use (if any)
   */
  widgetType$ = this.store.pipe(select((state) => state.widgetType));

  /**
   * Should {@link AppComponent} apply "widget-auto" class?
   *
   * This will be true if the application is running in a widget, and the widget is in "auto" layout mode.
   *
   * Otherwise this will be false.
   */
  shouldApplyWidgetAutoLayout$ = ElfCombineQueries([this.isInWidget$, this.widgetType$]).pipe(
    map(([isInWidget, widgetType]) => isInWidget && widgetType === 'auto'),
  );

  /**
   * Should {@link AppComponent} apply "widget-fixed" class?
   *
   * This will be true if the application is running in a widget, and the widget is in "fixed" layout mode.
   *
   * Otherwise this will be false.
   */
  shouldApplyWidgetFixedLayout$ = ElfCombineQueries([this.isInWidget$, this.widgetType$]).pipe(
    map(([isInWidget, widgetType]) => isInWidget && widgetType === 'fixed'),
  );

  ////////////////////////////////////////////////////////////////////
  // APPLICATION VIEWPORT RESIZING
  ////////////////////////////////////////////////////////////////////

  /**
   * Occurs when the width of the viewport changes.
   */
  viewportWidth$ = this.store.pipe(select((state) => state.viewportWidth));

  /**
   * Occurs when the height of the viewport changes.
   */
  private _viewportHeight$ = this.store.pipe(select((state) => state.viewportHeight));

  /**
   * Occurs when the width or height of the viewport changes.
   */
  viewportSize$ = ElfCombineQueries([this.viewportWidth$, this._viewportHeight$]).pipe(map(([width, height]) => ({ width, height })));

  /////////////////////////////////////////////////////////////////////////////////////////
  // Shell Primary Outlet Container
  /////////////////////////////////////////////////////////////////////////////////////////

  private _shellLayout$ = this.store.pipe(select((state) => state.layout));

  /**
   * Occurs when the Shell Layout changes.
   */
  shellLayout$ = this._shellLayout$.pipe(
    map((layout) => layout.layout),
    delay(0),
  );

  /**
   * Occurs when the Shell Layout Alignment changes.
   */
  shellLayoutAlign$ = this._shellLayout$.pipe(
    map((layout) => layout.layoutAlign),
    delay(0),
  );

  /**
   * Occurs when the XS Layout changes.
   */
  outletLayoutColumnXS$ = this._shellLayout$.pipe(
    map((layout) => {
      const target = layout.mobile;
      const grow = target.grow;
      const shrink = target.shrink;
      const basis = target.basis;
      return `${grow} ${shrink} ${basis}`;
    }),
    delay(0),
  );

  /**
   * Occurs when the GTXS Layout changes.
   */
  outletLayoutColumnGTXS$ = this._shellLayout$.pipe(
    map((layout) => {
      const target = layout.desktop;
      const grow = target.grow;
      const shrink = target.shrink;
      const basis = target.basis;
      return `${grow} ${shrink} ${basis}`;
    }),
    delay(0),
  );

  /////////////////////////////////////////////////////////////////////////////////////////
  // Misc
  /////////////////////////////////////////////////////////////////////////////////////////

  private _loadingIndicator$ = this.store.pipe(select((state) => state.loadingIndicator));

  /**
   * Occurs when the loading indicator for the app changes state.
   */
  loadingIndicator$ = this._loadingIndicator$.pipe(
    // when the loading indicator changes
    switchMap((active) => {
      // if active
      if (active === true) {
        // create a interval delay
        return interval(DEFAULT_LOADING_INDICATOR_DISPLAY_DELAY).pipe(
          // we only need one
          first(),
          // return true
          switchMap(() => {
            return of(true);
          }),
        );
      } else {
        // return false
        return of(false);
      }
    }),
  );

  /**
   * The orientation of the page.
   */
  orientation$ = this.store.pipe(
    select((state) => state.orientation),
    delay(0),
  );

  ////////////////////////////////////////////////////////////////////
  // First Interaction
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the first interaction occurred?
   */
  hasFirstInteraction$ = this.store.pipe(select((state) => state.hasFirstInteraction));

  ////////////////////////////////////////////////////////////////////
  // Window Unloading
  ////////////////////////////////////////////////////////////////////

  // prevent window unload dialog
  private preventWindowUnloadDialog$ = this.store.pipe(select((state) => state.preventWindowUnloadDialog));

  /**
   * Occurs when the application is about to exit.
   */
  preventAppUnloadDialog$ = ElfCombineQueries([this.preventWindowUnloadDialog$, this.isInWidget$]).pipe(
    map(([preventWindowUnloadDialog, isInWidget]) => preventWindowUnloadDialog || isInWidget),
  );
}
