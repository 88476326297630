import { produce } from 'immer';

/**
 * Creates an updater function that uses Immer to provide immutable state updates.
 * @param updater A function that defines how the state should be mutated.
 */
export function ElfWrite<S>(updater: (state: S) => void): (state: S) => S {
  return function (state) {
    return produce(state, (draft) => {
      updater(draft as S);
    });
  };
}
