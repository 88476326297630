import { HttpBackend, HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, from, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, toArray } from 'rxjs/operators';

/**
 * The Image Loader Service will generate a bunch of off screen image elements in order to pre-load
 * images to disk. Note this won't work with cache disabled.
 */
@Injectable({
  providedIn: 'root',
})
export class ImageLoaderService {
  private http: HttpClient;
  /**
   * Constructor
   *
   * @param http - uses angular http client
   */
  constructor(readonly handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  /**
   * Loads a bunch of images and return them.
   *
   * The order of the input array will be the order of the output array.
   *
   * The input is an array of strings representing URLs.
   * The output is an array of strings representing URLs.
   *
   * @param urls - an array of URLs
   */
  loadImagesToCache(urls: string[]) {
    // check the object exists
    if (!urls || !urls.length) {
      return throwError(() => new Error('[Image Loader Service] - You must provide an array of images when using the image loader.'));
    }

    // check each object provided is valid
    let isValid = true;
    urls.map((targetURL) => {
      if (targetURL.length === 0) {
        isValid = false;
      }
    });

    // if valid, load all images
    if (isValid) {
      const loadImage = (imagePath: string) => {
        return new Observable<string>((observer) => {
          const img = new Image();
          img.src = imagePath;
          img.onload = () => {
            observer.next(img.src);
            observer.complete();
          };
          img.onerror = (err) => {
            observer.error(err);
          };
        }).pipe(
          retryBackoff({
            initialInterval: 500,
            maxRetries: 3,
            maxInterval: 5000,
          }),
        );
      };

      return from(urls).pipe(mergeMap(loadImage), toArray(), take(1));
    } else {
      return throwError(() => new Error('[Image Loader Service] - The loader must be presented with valid URLs to load.'));
    }
  }

  /**
   * Loads a bunch of images and return them.
   *
   * The order of the input array will be the order of the output array.
   *
   * The input is an array of strings representing URLs.
   * The output is an array of strings representing src data.
   *
   * @param urls - an array of URLs
   */
  loadImagesToBlob(urls: string[]) {
    // check the object exists
    if (!urls || !urls.length) {
      return throwError(
        () => new Error('[Image Loader Service] : loadImagesToBlob - You must provide an array of images when using the image loader.'),
      );
    }

    // check each object provided is valid
    let isValid = true;
    urls.map((targetURL) => {
      if (targetURL.length === 0) {
        isValid = false;
      }
    });

    // if valid, load all images
    if (isValid) {
      const loadImage = (imagePath: string) => {
        return this.http
          .get(imagePath, {
            responseType: 'blob',
          })
          .pipe(
            retryBackoff({
              initialInterval: 500,
              maxRetries: 3,
              maxInterval: 5000,
            }),
          );
      };

      // const requests = urls.map((targetURL) => {
      //   return this.http.get(targetURL, {
      //     responseType: 'blob',
      //   });
      // });

      return from(urls).pipe(mergeMap(loadImage), toArray(), take(1));
    } else {
      return throwError(() => {
        return new Error('[Image Loader Service] : loadImagesAsBlob- The loader must be presented with valid URLs to load.');
      });
    }
  }

  /**
   * Loads an Image and returns the blob.
   *
   * The input is an URL to an image.
   * The output is a string representing src data.
   *
   * @param url - The URL of the image to load.
   */
  loadImageToBlob(url: string) {
    // check the object exists
    if (!url) {
      return throwError(
        () => new Error('[Image Loader Service] : loadImageToBlob - You must provide a valid image URL when using the image loader.'),
      );
    }

    // check the URL provided is valid
    if (url.length === 0) {
      return throwError(() => new Error('[Image Loader Service] : loadImageToBlob - The provided URL is invalid.'));
    }

    // load the image with progress
    return this.http
      .get(url, {
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        retryBackoff({
          initialInterval: 500,
          maxRetries: 3,
          maxInterval: 5000,
        }),
        filter((event) => event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.Response),
        map((event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
            return { progress };
          } else if (event.type === HttpEventType.Response) {
            return { progress: 100, blob: event.body };
          }
          return { progress: 0 };
        }),
        catchError((error) => throwError(() => new Error(`[Image Loader Service] : loadImageToBlob - ${error.message}`))),
      );
  }
}
