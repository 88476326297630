import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { ElfWrite } from 'src/app/util/ElfWrite';

interface CredentialModel {
  credential: unknown;
}

/**
 * The Default State
 */
function initialState(): CredentialModel {
  return {
    credential: {},
  };
}

/**
 * Stores the Credential
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `credential}`,
    },
    withProps<CredentialModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Apply the credential to the store
   *
   * @param credential - The configuration from the server.
   */
  applyCredential(credential?: unknown) {
    if (!credential) {
      return;
    }

    this.store.update(
      ElfWrite((state) => {
        state.credential = credential;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Returns the latest credential
   */
  credential$ = this.store.pipe(select((state) => state.credential));
}
