<!-- Container just to set flex layout as it's all I "know" :D -->
<div fxLayout="row" fxLayoutAlign="center center">
  <!-- The content part of the template is always shown and contains the list of errors which is an array -->
  @if (data?.messages) {
    <div>
      <ul>
        <!-- Each error entry in the array gets it's own error list item -->
        @for (error of data?.messages; track error) {
          <li>{{ error }}</li>
        }
      </ul>
    </div>
  }
  <!-- This part is only shown when a button exists which is optional -->
  @if (data?.action?.label) {
    <div>
      <button mat-button (click)="onDismiss()">{{ data.action.label }}</button>
    </div>
  }
</div>
