import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { createDigitaServiceError } from 'src/app/app-error';

/**
 * The Scrolling Service
 */
@Injectable({
  providedIn: 'root',
})
export class ScrollingService {
  /**
   * A reference to the window.
   */
  private window = this.document.defaultView;

  /**
   * Constructor
   */
  constructor(
    private readonly viewportRuler: ViewportRuler,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    gsap.registerPlugin(ScrollToPlugin);
  }

  /**
   * Scroll the document to the top.
   *
   * This action will only work once a first interaction has occurred.
   */
  applyScrollToTop(duration = 0.5, delay = 0, onComplete?: () => void) {
    if (this.window) {
      // kill any existing tween
      gsap.killTweensOf(this.window);

      gsap.to(this.window, {
        duration,
        delay,
        scrollTo: {
          y: 0,
        },
        ease: 'power1',
        onComplete,
      });
    }
  }

  /**
   * Scroll the document to the top.
   *
   * This action will only work once a first interaction has occurred.
   */
  applyScrollToBottom(duration = 0.5, delay = 0, onComplete?: () => void) {
    if (this.window) {
      // kill any existing tween
      gsap.killTweensOf(this.window);

      gsap.to(this.window, {
        duration,
        delay,
        scrollTo: {
          y: 'max',
        },
        ease: 'power1',
        onComplete,
      });
    }
  }

  /**
   * Scroll to an element on the page.
   *
   * @param element - the element to scroll to
   * @param duration - the duration in seconds
   * @param delay - the delay in seconds
   * @param location - can be 'center' or 'top' depending on if the element should scroll in the center of the page or the top
   * @param onlyScrollIfNotVisible - will only scroll if the element is not visible
   * @param onComplete - the callback once scrolling is completed.
   */
  applyScrollToElement(
    element: HTMLElement,
    duration = 0.75,
    delay = 0,
    location: 'center' | 'top' | 'bottom' = 'center',
    onlyScrollIfNotVisible = false,
    onComplete?: () => void,
  ) {
    if (!element) {
      throw createDigitaServiceError(
        `ScrollingService`,
        `applyScrollToElement`,
        `The element provided to scroll to is undefined.`,
        `internal`,
      );
    }

    // get the elements bounding box
    const nodeBoundingBox = element.getBoundingClientRect();

    // get the elements top position by default
    let nodeScrollingPoint = nodeBoundingBox.top;

    // if location is bottom
    if (location === 'bottom') {
      nodeScrollingPoint = nodeBoundingBox.bottom;
    }

    // if only scroll if visible
    if (onlyScrollIfNotVisible) {
      const top = nodeBoundingBox.top;
      const bottom = nodeBoundingBox.bottom;

      if (top >= 0 && bottom <= document.documentElement.clientHeight) {
        if (onComplete) {
          onComplete();
        }
        return;
      }
    }

    // get the elements document offset
    const nodeScrollingPointOffset = this.window.pageYOffset;

    // the top is considered default
    let viewportTarget = 0;

    if (location === 'center') {
      // get half the size of the viewport is needed if aligning to center
      viewportTarget = this.viewportRuler.getViewportSize().height / 2;
    } else if (location === 'bottom') {
      // get the full height of the viewport if aligning to bottom
      viewportTarget = this.viewportRuler.getViewportSize().height;
    }

    // Add them all together
    const finalPoint = Math.round(nodeScrollingPoint + nodeScrollingPointOffset - viewportTarget);

    // if the scroll position of the document is the same as the target position of the document, the scroll
    // should be almost instant.
    const alreadyScrolledThere = document.scrollingElement.scrollTop === finalPoint ? true : false;

    // navigate to the final point
    gsap.to(this.window, {
      duration: alreadyScrolledThere ? 0.01 : duration,
      scrollTo: {
        y: finalPoint,
        autoKill: false,
      },
      ease: 'power1',
      delay: alreadyScrolledThere ? 0 : delay,
      onComplete,
    });
  }
}
