/* eslint-disable @typescript-eslint/no-explicit-any */

import { Observable, combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

/**
 * https://github.com/ngneat/elf/discussions/62
 */
type ReturnTypes<T extends Observable<any>[]> = { [P in keyof T]: T[P] extends Observable<infer R> ? R : never };
type Observables = [Observable<any>] | Observable<any>[];

export function ElfCombineQueries<R extends Observables>(observables: R): Observable<ReturnTypes<R>> {
  return combineLatest(observables).pipe(auditTime(0)) as any;
}
