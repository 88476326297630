/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { FlexModule } from '@ngbracket/ngx-layout/flex';

@Component({
  selector: 'app-list-snackbar',
  templateUrl: './list-snackbar.component.html',
  styleUrls: ['./list-snackbar.component.scss'],
  standalone: true,
  imports: [FlexModule, MatButton],
})
/**
 * The ListSnackbarComponent is used to display a multi-line bullet point message to the user in a snackbar.
 */
export class ListSnackbarComponent {
  /**
   * Constructor
   */
  constructor(
    public snackBarRef: MatSnackBarRef<ListSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {
    console.log(data);
  }

  /**
   * Occurs when an Action Button (optional) has been clicked.
   *
   * @param event - the native mouse event
   */
  onDismiss() {
    this.snackBarRef?.dismiss();
  }
}
