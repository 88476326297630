!function (t, e) {
  "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? exports.DigitaService = e() : (t.DigitaService = t.DigitaService || {}, t.DigitaService.Utils = e());
}(self, function () {
  return function () {
    var t = {
        4126: function (t, e, n) {
          const i = n(570);
          t.exports = {
            iframeResize: i,
            iframeResizer: i,
            contentWindow: n(481)
          };
        },
        481: function (t) {
          !function (e) {
            if ("undefined" != typeof window) {
              var n = !0,
                i = 10,
                o = "",
                r = 0,
                s = "",
                a = null,
                u = "",
                c = !1,
                f = {
                  resize: 1,
                  click: 1
                },
                l = 128,
                d = !0,
                m = 1,
                p = "bodyOffset",
                h = p,
                g = !0,
                v = "",
                y = {},
                b = 32,
                w = null,
                O = !1,
                E = !1,
                x = "[iFrameSizer]",
                T = x.length,
                S = "",
                I = {
                  max: 1,
                  min: 1,
                  bodyScroll: 1,
                  documentElementScroll: 1
                },
                R = "child",
                F = !0,
                M = window.parent,
                z = "*",
                C = 0,
                j = !1,
                k = null,
                N = 16,
                P = 1,
                A = "scroll",
                L = A,
                D = window,
                W = function () {
                  st("onMessage function not defined");
                },
                H = function () {},
                _ = function () {},
                B = {
                  height: function () {
                    return st("Custom height calculation function not defined"), document.documentElement.offsetHeight;
                  },
                  width: function () {
                    return st("Custom width calculation function not defined"), document.body.scrollWidth;
                  }
                },
                U = {},
                V = !1;
              try {
                var q = Object.create({}, {
                  passive: {
                    get: function () {
                      V = !0;
                    }
                  }
                });
                window.addEventListener("test", nt, q), window.removeEventListener("test", nt, q);
              } catch (t) {}
              var Y,
                G,
                X,
                J,
                K,
                Q,
                $,
                Z = {
                  bodyOffset: function () {
                    return document.body.offsetHeight + vt("marginTop") + vt("marginBottom");
                  },
                  offset: function () {
                    return Z.bodyOffset();
                  },
                  bodyScroll: function () {
                    return document.body.scrollHeight;
                  },
                  custom: function () {
                    return B.height();
                  },
                  documentElementOffset: function () {
                    return document.documentElement.offsetHeight;
                  },
                  documentElementScroll: function () {
                    return document.documentElement.scrollHeight;
                  },
                  max: function () {
                    return Math.max.apply(null, bt(Z));
                  },
                  min: function () {
                    return Math.min.apply(null, bt(Z));
                  },
                  grow: function () {
                    return Z.max();
                  },
                  lowestElement: function () {
                    return Math.max(Z.bodyOffset() || Z.documentElementOffset(), yt("bottom", Ot()));
                  },
                  taggedElement: function () {
                    return wt("bottom", "data-iframe-height");
                  }
                },
                tt = {
                  bodyScroll: function () {
                    return document.body.scrollWidth;
                  },
                  bodyOffset: function () {
                    return document.body.offsetWidth;
                  },
                  custom: function () {
                    return B.width();
                  },
                  documentElementScroll: function () {
                    return document.documentElement.scrollWidth;
                  },
                  documentElementOffset: function () {
                    return document.documentElement.offsetWidth;
                  },
                  scroll: function () {
                    return Math.max(tt.bodyScroll(), tt.documentElementScroll());
                  },
                  max: function () {
                    return Math.max.apply(null, bt(tt));
                  },
                  min: function () {
                    return Math.min.apply(null, bt(tt));
                  },
                  rightMostElement: function () {
                    return yt("right", Ot());
                  },
                  taggedElement: function () {
                    return wt("right", "data-iframe-width");
                  }
                },
                et = (Y = Et, K = null, Q = 0, $ = function () {
                  Q = Date.now(), K = null, J = Y.apply(G, X), K || (G = X = null);
                }, function () {
                  var t = Date.now();
                  Q || (Q = t);
                  var e = N - (t - Q);
                  return G = this, X = arguments, e <= 0 || e > N ? (K && (clearTimeout(K), K = null), Q = t, J = Y.apply(G, X), K || (G = X = null)) : K || (K = setTimeout($, e)), J;
                });
              "iframeResizer" in window || (window.iframeChildListener = function (t) {
                Ft({
                  data: t,
                  sameDomian: !0
                });
              }, it(window, "message", Ft), it(window, "readystatechange", Mt), Mt());
            }
            function nt() {}
            function it(t, e, n, i) {
              t.addEventListener(e, n, !!V && (i || {}));
            }
            function ot(t) {
              return t.charAt(0).toUpperCase() + t.slice(1);
            }
            function rt(t) {
              O && window.console;
            }
            function st(t) {
              window.console;
            }
            function at() {
              var t;
              !function () {
                function t(t) {
                  return "true" === t;
                }
                var i = v.slice(T).split(":");
                S = i[0], r = e === i[1] ? r : Number(i[1]), c = e === i[2] ? c : t(i[2]), O = e === i[3] ? O : t(i[3]), b = e === i[4] ? b : Number(i[4]), n = e === i[6] ? n : t(i[6]), s = i[7], h = e === i[8] ? h : i[8], o = i[9], u = i[10], C = e === i[11] ? C : Number(i[11]), y.enable = e !== i[12] && t(i[12]), R = e === i[13] ? R : i[13], L = e === i[14] ? L : i[14], E = e === i[15] ? E : t(i[15]);
              }(), rt(window.location.href), function () {
                function t() {
                  var t = window.iFrameResizer;
                  rt(JSON.stringify(t)), Object.keys(t).forEach(ut, t), W = "onMessage" in t ? t.onMessage : W, H = "onReady" in t ? t.onReady : H, z = "targetOrigin" in t ? t.targetOrigin : z, h = "heightCalculationMethod" in t ? t.heightCalculationMethod : h, L = "widthCalculationMethod" in t ? t.widthCalculationMethod : L;
                }
                function e(t, e) {
                  return "function" == typeof t && (rt(), B[e] = t, t = "custom"), t;
                }
                "iFrameResizer" in window && Object === window.iFrameResizer.constructor && (t(), h = e(h, "height"), L = e(L, "width"));
                rt();
              }(), function () {
                e === s && (s = r + "px");
                ct("margin", function (t, e) {
                  -1 !== e.indexOf("-") && (st(), e = "");
                  return e;
                }(0, s));
              }(), ct("background", o), ct("padding", u), (t = document.createElement("div")).style.clear = "both", t.style.display = "block", t.style.height = "0", document.body.appendChild(t), mt(), pt(), document.documentElement.style.height = "", document.body.style.height = "", rt(), rt(), D.parentIFrame = {
                autoResize: function (t) {
                  return !0 === t && !1 === n ? (n = !0, ht()) : !1 === t && !0 === n && (n = !1, lt("remove"), null !== a && a.disconnect(), clearInterval(w)), Rt(0, 0, "autoResize", JSON.stringify(n)), n;
                },
                close: function () {
                  Rt(0, 0, "close");
                },
                getId: function () {
                  return S;
                },
                getPageInfo: function (t) {
                  "function" == typeof t ? (_ = t, Rt(0, 0, "pageInfo")) : (_ = function () {}, Rt(0, 0, "pageInfoStop"));
                },
                moveToAnchor: function (t) {
                  y.findTarget(t);
                },
                reset: function () {
                  It("parentIFrame.reset");
                },
                scrollTo: function (t, e) {
                  Rt(e, t, "scrollTo");
                },
                scrollToOffset: function (t, e) {
                  Rt(e, t, "scrollToOffset");
                },
                sendMessage: function (t, e) {
                  Rt(0, 0, "message", JSON.stringify(t), e);
                },
                setHeightCalculationMethod: function (t) {
                  h = t, mt();
                },
                setWidthCalculationMethod: function (t) {
                  L = t, pt();
                },
                setTargetOrigin: function (t) {
                  rt(), z = t;
                },
                size: function (t, e) {
                  xt("size", "parentIFrame.size(" + (t || "") + (e ? "," + e : "") + ")", t, e);
                }
              }, function () {
                if (!0 !== E) return;
                function t(t) {
                  Rt(0, 0, t.type, t.screenY + ":" + t.screenX);
                }
                function e(e, n) {
                  rt(), it(window.document, e, t);
                }
                e("mouseenter", "Mouse Enter"), e("mouseleave", "Mouse Leave");
              }(), ht(), y = function () {
                function t() {
                  return {
                    x: window.pageXOffset === e ? document.documentElement.scrollLeft : window.pageXOffset,
                    y: window.pageYOffset === e ? document.documentElement.scrollTop : window.pageYOffset
                  };
                }
                function n(e) {
                  var n = e.getBoundingClientRect(),
                    i = t();
                  return {
                    x: parseInt(n.left, 10) + parseInt(i.x, 10),
                    y: parseInt(n.top, 10) + parseInt(i.y, 10)
                  };
                }
                function i(t) {
                  function i(t) {
                    var e = n(t);
                    rt((e.x, e.y)), Rt(e.y, e.x, "scrollToOffset");
                  }
                  var o = t.split("#")[1] || t,
                    r = decodeURIComponent(o),
                    s = document.getElementById(r) || document.getElementsByName(r)[0];
                  e === s ? (rt(), Rt(0, 0, "inPageLink", "#" + o)) : i(s);
                }
                function o() {
                  var t = window.location.hash,
                    e = window.location.href;
                  "" !== t && "#" !== t && i(e);
                }
                function r() {
                  function t(t) {
                    function e(t) {
                      t.preventDefault(), i(this.getAttribute("href"));
                    }
                    "#" !== t.getAttribute("href") && it(t, "click", e);
                  }
                  Array.prototype.forEach.call(document.querySelectorAll('a[href^="#"]'), t);
                }
                function s() {
                  it(window, "hashchange", o);
                }
                function a() {
                  setTimeout(o, l);
                }
                function u() {
                  Array.prototype.forEach && document.querySelectorAll ? (rt(), r(), s(), a()) : st();
                }
                y.enable ? u() : rt();
                return {
                  findTarget: i
                };
              }(), xt("init", "Init message from host page"), H();
            }
            function ut(t) {
              var e = t.split("Callback");
              if (2 === e.length) {
                var n = "on" + e[0].charAt(0).toUpperCase() + e[0].slice(1);
                this[n] = this[t], delete this[t], st();
              }
            }
            function ct(t, n) {
              e !== n && "" !== n && "null" !== n && (document.body.style[t] = n, rt());
            }
            function ft(t) {
              var e = {
                add: function (e) {
                  function n() {
                    xt(t.eventName, t.eventType);
                  }
                  U[e] = n, it(window, e, n, {
                    passive: !0
                  });
                },
                remove: function (t) {
                  var e,
                    n,
                    i,
                    o = U[t];
                  delete U[t], e = window, n = t, i = o, e.removeEventListener(n, i, !1);
                }
              };
              t.eventNames && Array.prototype.map ? (t.eventName = t.eventNames[0], t.eventNames.map(e[t.method])) : e[t.method](t.eventName), rt((ot(t.method), t.eventType));
            }
            function lt(t) {
              ft({
                method: t,
                eventType: "Animation Start",
                eventNames: ["animationstart", "webkitAnimationStart"]
              }), ft({
                method: t,
                eventType: "Animation Iteration",
                eventNames: ["animationiteration", "webkitAnimationIteration"]
              }), ft({
                method: t,
                eventType: "Animation End",
                eventNames: ["animationend", "webkitAnimationEnd"]
              }), ft({
                method: t,
                eventType: "Input",
                eventName: "input"
              }), ft({
                method: t,
                eventType: "Mouse Up",
                eventName: "mouseup"
              }), ft({
                method: t,
                eventType: "Mouse Down",
                eventName: "mousedown"
              }), ft({
                method: t,
                eventType: "Orientation Change",
                eventName: "orientationchange"
              }), ft({
                method: t,
                eventType: "Print",
                eventNames: ["afterprint", "beforeprint"]
              }), ft({
                method: t,
                eventType: "Ready State Change",
                eventName: "readystatechange"
              }), ft({
                method: t,
                eventType: "Touch Start",
                eventName: "touchstart"
              }), ft({
                method: t,
                eventType: "Touch End",
                eventName: "touchend"
              }), ft({
                method: t,
                eventType: "Touch Cancel",
                eventName: "touchcancel"
              }), ft({
                method: t,
                eventType: "Transition Start",
                eventNames: ["transitionstart", "webkitTransitionStart", "MSTransitionStart", "oTransitionStart", "otransitionstart"]
              }), ft({
                method: t,
                eventType: "Transition Iteration",
                eventNames: ["transitioniteration", "webkitTransitionIteration", "MSTransitionIteration", "oTransitionIteration", "otransitioniteration"]
              }), ft({
                method: t,
                eventType: "Transition End",
                eventNames: ["transitionend", "webkitTransitionEnd", "MSTransitionEnd", "oTransitionEnd", "otransitionend"]
              }), "child" === R && ft({
                method: t,
                eventType: "IFrame Resized",
                eventName: "resize"
              });
            }
            function dt(t, e, n, i) {
              return e !== t && (t in n || (st(), t = e), rt()), t;
            }
            function mt() {
              h = dt(h, p, Z);
            }
            function pt() {
              L = dt(L, A, tt);
            }
            function ht() {
              var t;
              !0 === n ? (lt("add"), t = 0 > b, window.MutationObserver || window.WebKitMutationObserver ? t ? gt() : a = function () {
                function t(t) {
                  function e(t) {
                    !1 === t.complete && (rt(t.src), t.addEventListener("load", o, !1), t.addEventListener("error", r, !1), u.push(t));
                  }
                  "attributes" === t.type && "src" === t.attributeName ? e(t.target) : "childList" === t.type && Array.prototype.forEach.call(t.target.querySelectorAll("img"), e);
                }
                function e(t) {
                  u.splice(u.indexOf(t), 1);
                }
                function n(t) {
                  rt(t.src), t.removeEventListener("load", o, !1), t.removeEventListener("error", r, !1), e(t);
                }
                function i(t, e, i) {
                  n(t.target), xt(e, i + ": " + t.target.src);
                }
                function o(t) {
                  i(t, "imageLoad", "Image loaded");
                }
                function r(t) {
                  i(t, "imageLoadFailed", "Image load failed");
                }
                function s(e) {
                  xt("mutationObserver", "mutationObserver: " + e[0].target + " " + e[0].type), e.forEach(t);
                }
                function a() {
                  var t = document.querySelector("body"),
                    e = {
                      attributes: !0,
                      attributeOldValue: !1,
                      characterData: !0,
                      characterDataOldValue: !1,
                      childList: !0,
                      subtree: !0
                    };
                  return f = new c(s), rt(), f.observe(t, e), f;
                }
                var u = [],
                  c = window.MutationObserver || window.WebKitMutationObserver,
                  f = a();
                return {
                  disconnect: function () {
                    "disconnect" in f && (rt(), f.disconnect(), u.forEach(n));
                  }
                };
              }() : (rt(), gt())) : rt();
            }
            function gt() {
              0 !== b && (rt(), w = setInterval(function () {
                xt("interval", "setInterval: " + b);
              }, Math.abs(b)));
            }
            function vt(t, e) {
              var n = 0;
              return e = e || document.body, n = null === (n = document.defaultView.getComputedStyle(e, null)) ? 0 : n[t], parseInt(n, i);
            }
            function yt(t, e) {
              for (var n = e.length, i = 0, o = 0, r = ot(t), s = Date.now(), a = 0; a < n; a++) (i = e[a].getBoundingClientRect()[t] + vt("margin" + r, e[a])) > o && (o = i);
              return s = Date.now() - s, rt(), rt(), function (t) {
                t > N / 2 && (N = 2 * t, rt());
              }(s), o;
            }
            function bt(t) {
              return [t.bodyOffset(), t.bodyScroll(), t.documentElementOffset(), t.documentElementScroll()];
            }
            function wt(t, e) {
              var n = document.querySelectorAll("[" + e + "]");
              return 0 === n.length && (st(), document.querySelectorAll("body *")), yt(t, n);
            }
            function Ot() {
              return document.querySelectorAll("body *");
            }
            function Et(t, n, i, o) {
              var r, s;
              !function () {
                function t(t, e) {
                  return !(Math.abs(t - e) <= C);
                }
                return r = e === i ? Z[h]() : i, s = e === o ? tt[L]() : o, t(m, r) || c && t(P, s);
              }() && "init" !== t ? !(t in {
                init: 1,
                interval: 1,
                size: 1
              }) && (h in I || c && L in I) ? It(n) : t in {
                interval: 1
              } || rt() : (Tt(), Rt(m = r, P = s, t));
            }
            function xt(t, e, n, i) {
              j && t in f ? rt() : (t in {
                reset: 1,
                resetPage: 1,
                init: 1
              } || rt(), "init" === t ? Et(t, e, n, i) : et(t, e, n, i));
            }
            function Tt() {
              j || (j = !0, rt()), clearTimeout(k), k = setTimeout(function () {
                j = !1, rt(), rt();
              }, l);
            }
            function St(t) {
              m = Z[h](), P = tt[L](), Rt(m, P, t);
            }
            function It(t) {
              var e = h;
              h = p, rt(), Tt(), St("reset"), h = e;
            }
            function Rt(t, n, i, o, r) {
              var s;
              !0 === F && (e === r ? r = z : rt(), s = S + ":" + t + ":" + n + ":" + i + (e === o ? "" : ":" + o), rt(), M.postMessage(x + s, r));
            }
            function Ft(n) {
              var i = {
                init: function () {
                  v = n.data, M = n.source, at(), d = !1, setTimeout(function () {
                    g = !1;
                  }, l);
                },
                reset: function () {
                  g ? rt() : (rt(), St("resetPage"));
                },
                resize: function () {
                  xt("resizeParent", "Parent window requested size check");
                },
                moveToAnchor: function () {
                  y.findTarget(r());
                },
                inPageLink: function () {
                  this.moveToAnchor();
                },
                pageInfo: function () {
                  var t = r();
                  rt(), _(JSON.parse(t)), rt();
                },
                message: function () {
                  var t = r();
                  rt(), W(JSON.parse(t)), rt();
                }
              };
              function o() {
                return n.data.split("]")[1].split(":")[0];
              }
              function r() {
                return n.data.slice(n.data.indexOf(":") + 1);
              }
              function s() {
                return n.data.split(":")[2] in {
                  true: 1,
                  false: 1
                };
              }
              function a() {
                var r = o();
                r in i ? i[r]() : !t.exports && "iFrameResize" in window || window.jQuery !== e && "iFrameResize" in window.jQuery.prototype || s() || st(n.data);
              }
              x === ("" + n.data).slice(0, T) && (!1 === d ? a() : s() ? i.init() : rt(o()));
            }
            function Mt() {
              "loading" !== document.readyState && window.parent.postMessage("[iFrameResizerChild]Ready", "*");
            }
          }();
        },
        570: function (t, e) {
          var n, i, o;
          !function (r) {
            if ("undefined" != typeof window) {
              var s,
                a,
                u = 0,
                c = !1,
                f = !1,
                l = 7,
                d = "[iFrameSizer]",
                m = d.length,
                p = null,
                h = window.requestAnimationFrame,
                g = Object.freeze({
                  max: 1,
                  scroll: 1,
                  bodyScroll: 1,
                  documentElementScroll: 1
                }),
                v = {},
                y = null,
                b = Object.freeze({
                  autoResize: !0,
                  bodyBackground: null,
                  bodyMargin: null,
                  bodyMarginV1: 8,
                  bodyPadding: null,
                  checkOrigin: !0,
                  inPageLinks: !1,
                  enablePublicMethods: !0,
                  heightCalculationMethod: "bodyOffset",
                  id: "iFrameResizer",
                  interval: 32,
                  license: "1jqr0si6pnt",
                  log: !1,
                  maxHeight: 1 / 0,
                  maxWidth: 1 / 0,
                  minHeight: 0,
                  minWidth: 0,
                  mouseEvents: !0,
                  resizeFrom: "parent",
                  scrolling: !1,
                  sizeHeight: !0,
                  sizeWidth: !1,
                  warningTimeout: 5e3,
                  tolerance: 0,
                  widthCalculationMethod: "scroll",
                  onClose: function () {
                    return !0;
                  },
                  onClosed: function () {},
                  onInit: function () {},
                  onMessage: function () {
                    R("onMessage function not defined");
                  },
                  onMouseEnter: function () {},
                  onMouseLeave: function () {},
                  onResized: function () {},
                  onScroll: function () {
                    return !0;
                  }
                }),
                w = {};
              window.jQuery !== r && ((a = window.jQuery).fn ? a.fn.iFrameResize || (a.fn.iFrameResize = function (t) {
                return this.filter("iframe").each(function (e, n) {
                  _(n, t);
                }).end();
              }) : I("", "Unable to bind to jQuery, it is not fully loaded.")), i = [], (o = "function" == typeof (n = Y) ? n.apply(e, i) : n) === r || (t.exports = o), window.iFrameResize = window.iFrameResize || Y();
            }
            function O() {
              return window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            }
            function E(t, e, n) {
              t.addEventListener(e, n, !1);
            }
            function x(t, e, n) {
              t.removeEventListener(e, n, !1);
            }
            function T(t) {
              return v[t] ? v[t].log : c;
            }
            function S(t, e) {
              F("log", t, e, T(t));
            }
            function I(t, e) {
              F("info", t, e, T(t));
            }
            function R(t, e) {
              F("warn", t, e, !0);
            }
            function F(t, e, n, i) {
              !0 === i && window.console;
            }
            function M(t) {
              function e() {
                o("Height"), o("Width"), D(function () {
                  L(C), N(_), h("onResized", C);
                }, C, "init");
              }
              function n(t) {
                return "border-box" !== t.boxSizing ? 0 : (t.paddingTop ? parseInt(t.paddingTop, 10) : 0) + (t.paddingBottom ? parseInt(t.paddingBottom, 10) : 0);
              }
              function i(t) {
                return "border-box" !== t.boxSizing ? 0 : (t.borderTopWidth ? parseInt(t.borderTopWidth, 10) : 0) + (t.borderBottomWidth ? parseInt(t.borderBottomWidth, 10) : 0);
              }
              function o(t) {
                var e = Number(v[_]["max" + t]),
                  n = Number(v[_]["min" + t]),
                  i = t.toLowerCase(),
                  o = Number(C[i]);
                S(_, "Checking " + i + " is in range " + n + "-" + e), o < n && (o = n, S(_, "Set " + i + " to min value")), o > e && (o = e, S(_, "Set " + i + " to max value")), C[i] = "" + o;
              }
              function r(t) {
                return M.slice(M.indexOf(":") + l + t);
              }
              function s(t, e) {
                var n, i, o;
                n = function () {
                  var n, i;
                  W("Send Page Info", "pageInfo:" + (n = document.body.getBoundingClientRect(), i = C.iframe.getBoundingClientRect(), JSON.stringify({
                    iframeHeight: i.height,
                    iframeWidth: i.width,
                    clientHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
                    clientWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                    offsetTop: parseInt(i.top - n.top, 10),
                    offsetLeft: parseInt(i.left - n.left, 10),
                    scrollTop: window.pageYOffset,
                    scrollLeft: window.pageXOffset,
                    documentHeight: document.documentElement.clientHeight,
                    documentWidth: document.documentElement.clientWidth,
                    windowHeight: window.innerHeight,
                    windowWidth: window.innerWidth
                  })), t, e);
                }, i = 32, w[o = e] || (w[o] = setTimeout(function () {
                  w[o] = null, n();
                }, i));
              }
              function a(t) {
                var e = t.getBoundingClientRect();
                return k(_), {
                  x: Math.floor(Number(e.left) + Number(p.x)),
                  y: Math.floor(Number(e.top) + Number(p.y))
                };
              }
              function u(t) {
                var e = t ? a(C.iframe) : {
                    x: 0,
                    y: 0
                  },
                  n = {
                    x: Number(C.width) + e.x,
                    y: Number(C.height) + e.y
                  };
                S(_, "Reposition requested from iFrame (offset x:" + e.x + " y:" + e.y + ")"), window.top === window.self ? (p = n, c(), S(_, "--")) : window.parentIFrame ? window.parentIFrame["scrollTo" + (t ? "Offset" : "")](n.x, n.y) : R(_, "Unable to scroll to requested position, window.parentIFrame not found");
              }
              function c() {
                !1 === h("onScroll", p) ? P() : N(_);
              }
              function f(t) {
                var e = {};
                if (0 === Number(C.width) && 0 === Number(C.height)) {
                  var n = r(9).split(":");
                  e = {
                    x: n[1],
                    y: n[0]
                  };
                } else e = {
                  x: C.width,
                  y: C.height
                };
                h(t, {
                  iframe: C.iframe,
                  screenX: Number(e.x),
                  screenY: Number(e.y),
                  type: C.type
                });
              }
              function h(t, e) {
                return z(_, t, e);
              }
              var g,
                y,
                b,
                O,
                T,
                F,
                M = t.data,
                C = {},
                _ = null;
              "[iFrameResizerChild]Ready" === M ? function () {
                for (var t in v) W("iFrame requested init", H(t), v[t].iframe, t);
              }() : d === ("" + M).slice(0, m) && M.slice(m).split(":")[0] in v ? (b = M.slice(m).split(":"), O = b[1] ? parseInt(b[1], 10) : 0, T = v[b[0]] && v[b[0]].iframe, F = getComputedStyle(T), C = {
                iframe: T,
                id: b[0],
                height: O + n(F) + i(F),
                width: b[2],
                type: b[3]
              }, _ = C.id, v[_] && (v[_].loaded = !0), (y = C.type in {
                true: 1,
                false: 1,
                undefined: 1
              }) && S(_, "Ignoring init message from meta parent page"), !y && function (t) {
                var e = !0;
                return v[t] || (e = !1, R(C.type + " No settings for " + t + ". Message was: " + M)), e;
              }(_) && (S(_, "Received: " + M), g = !0, null === C.iframe && (R(_, "IFrame (" + C.id + ") not found"), g = !1), g && function () {
                var e,
                  n = t.origin,
                  i = v[_] && v[_].checkOrigin;
                if (i && "" + n != "null" && !(i.constructor === Array ? function () {
                  var t = 0,
                    e = !1;
                  for (S(_, "Checking connection is from allowed list of origins: " + i); t < i.length; t++) if (i[t] === n) {
                    e = !0;
                    break;
                  }
                  return e;
                }() : (e = v[_] && v[_].remoteHost, S(_, "Checking connection is from: " + e), n === e))) throw new Error("Unexpected message received from: " + n + " for " + C.iframe.id + ". Message was: " + t.data + ". This error can be disabled by setting the checkOrigin: false option or by providing of array of trusted domains.");
                return !0;
              }() && function () {
                switch (v[_] && v[_].firstRun && v[_] && (v[_].firstRun = !1), C.type) {
                  case "close":
                    j(C.iframe);
                    break;
                  case "message":
                    l = r(6), S(_, "onMessage passed: {iframe: " + C.iframe.id + ", message: " + l + "}"), h("onMessage", {
                      iframe: C.iframe,
                      message: JSON.parse(l)
                    }), S(_, "--");
                    break;
                  case "mouseenter":
                    f("onMouseEnter");
                    break;
                  case "mouseleave":
                    f("onMouseLeave");
                    break;
                  case "autoResize":
                    v[_].autoResize = JSON.parse(r(9));
                    break;
                  case "scrollTo":
                    u(!1);
                    break;
                  case "scrollToOffset":
                    u(!0);
                    break;
                  case "pageInfo":
                    s(v[_] && v[_].iframe, _), function () {
                      function t(t, i) {
                        function o() {
                          v[n] ? s(v[n].iframe, n) : e();
                        }
                        ["scroll", "resize"].forEach(function (e) {
                          S(n, t + e + " listener for sendPageInfo"), i(window, e, o);
                        });
                      }
                      function e() {
                        t("Remove ", x);
                      }
                      var n = _;
                      t("Add ", E), v[n] && (v[n].stopPageInfo = e);
                    }();
                    break;
                  case "pageInfoStop":
                    v[_] && v[_].stopPageInfo && (v[_].stopPageInfo(), delete v[_].stopPageInfo);
                    break;
                  case "inPageLink":
                    n = r(9).split("#")[1] || "", i = decodeURIComponent(n), (o = document.getElementById(i) || document.getElementsByName(i)[0]) ? (t = a(o), S(_, "Moving to in page link (#" + n + ") at x: " + t.x + " y: " + t.y), p = {
                      x: t.x,
                      y: t.y
                    }, c(), S(_, "--")) : window.top === window.self ? S(_, "In page link #" + n + " not found") : window.parentIFrame ? window.parentIFrame.moveToAnchor(n) : S(_, "In page link #" + n + " not found and window.parentIFrame not found");
                    break;
                  case "reset":
                    A(C);
                    break;
                  case "init":
                    e(), h("onInit", C.iframe);
                    break;
                  default:
                    0 === Number(C.width) && 0 === Number(C.height) ? R("Unsupported message received (" + C.type + "), this is likely due to the iframe containing a later version of iframe-resizer than the parent page") : e();
                }
                var t, n, i, o, l;
              }())) : I(_, "Ignored: " + M);
            }
            function z(t, e, n) {
              var i = null,
                o = null;
              if (v[t]) {
                if ("function" != typeof (i = v[t][e])) throw new TypeError(e + " on iFrame[" + t + "] is not a function");
                o = i(n);
              }
              return o;
            }
            function C(t) {
              var e = t.id;
              delete v[e];
            }
            function j(t) {
              var e = t.id;
              if (!1 !== z(e, "onClose", e)) {
                S(e, "Removing iFrame: " + e);
                try {
                  t.parentNode && t.parentNode.removeChild(t);
                } catch (t) {
                  R(t);
                }
                z(e, "onClosed", e), S(e, "--"), C(t), s && (s.disconnect(), s = null);
              } else S(e, "Close iframe cancelled by onClose event");
            }
            function k(t) {
              null === p && S(t, "Get page position: " + (p = {
                x: window.pageXOffset === r ? document.documentElement.scrollLeft : window.pageXOffset,
                y: window.pageYOffset === r ? document.documentElement.scrollTop : window.pageYOffset
              }).x + "," + p.y);
            }
            function N(t) {
              null !== p && (window.scrollTo(p.x, p.y), S(t, "Set page position: " + p.x + "," + p.y), P());
            }
            function P() {
              p = null;
            }
            function A(t) {
              S(t.id, "Size reset requested by " + ("init" === t.type ? "host page" : "iFrame")), k(t.id), D(function () {
                L(t), W("reset", "reset", t.iframe, t.id);
              }, t, "reset");
            }
            function L(t) {
              function e(e) {
                f || "0" !== t[e] || (f = !0, S(i, "Hidden iFrame detected, creating visibility listener"), function () {
                  function t() {
                    function t(t) {
                      function e(e) {
                        return "0px" === (v[t] && v[t].iframe.style[e]);
                      }
                      function n(t) {
                        return null !== t.offsetParent;
                      }
                      v[t] && n(v[t].iframe) && (e("height") || e("width")) && W("Visibility change", "resize", v[t].iframe, t);
                    }
                    Object.keys(v).forEach(function (e) {
                      t(e);
                    });
                  }
                  function e(e) {
                    S("window", "Mutation observed: " + e[0].target + " " + e[0].type), B(t, 16);
                  }
                  function n() {
                    var t = document.querySelector("body"),
                      n = {
                        attributes: !0,
                        attributeOldValue: !1,
                        characterData: !0,
                        characterDataOldValue: !1,
                        childList: !0,
                        subtree: !0
                      };
                    new i(e).observe(t, n);
                  }
                  var i = O();
                  i && n();
                }());
              }
              function n(n) {
                !function (e) {
                  t.id ? (t.iframe.style[e] = t[e] + "px", S(t.id, "IFrame (" + i + ") " + e + " set to " + t[e] + "px")) : S("undefined", "messageData id not set");
                }(n), e(n);
              }
              var i = t.iframe.id;
              v[i] && (v[i].sizeHeight && n("height"), v[i].sizeWidth && n("width"));
            }
            function D(t, e, n) {
              n !== e.type && h && !window.jasmine ? (S(e.id, "Requesting animation frame"), h(t)) : t();
            }
            function W(t, e, n, i, o) {
              var r,
                s = !1;
              i = i || n.id, v[i] && (n && "contentWindow" in n && null !== n.contentWindow ? (r = v[i] && v[i].targetOrigin, S(i, "[" + t + "] Sending msg to iframe[" + i + "] (" + e + ") targetOrigin: " + r), n.contentWindow.postMessage(d + e, r)) : R(i, "[" + t + "] IFrame(" + i + ") not found"), o && v[i] && v[i].warningTimeout && (v[i].msgTimeout = setTimeout(function () {
                !v[i] || v[i].loaded || s || (s = !0, R(i, "IFrame has not responded within " + v[i].warningTimeout / 1e3 + " seconds. Check iFrameResizer.contentWindow.js has been loaded in iFrame. This message can be ignored if everything is working, or you can set the warningTimeout option to a higher value or zero to suppress this warning."));
              }, v[i].warningTimeout)));
            }
            function H(t) {
              return t + ":" + v[t].bodyMarginV1 + ":" + v[t].sizeWidth + ":" + v[t].log + ":" + v[t].interval + ":" + v[t].enablePublicMethods + ":" + v[t].autoResize + ":" + v[t].bodyMargin + ":" + v[t].heightCalculationMethod + ":" + v[t].bodyBackground + ":" + v[t].bodyPadding + ":" + v[t].tolerance + ":" + v[t].inPageLinks + ":" + v[t].resizeFrom + ":" + v[t].widthCalculationMethod + ":" + v[t].mouseEvents;
            }
            function _(t, e) {
              function n(t) {
                var e = t.split("Callback");
                if (2 === e.length) {
                  var n = "on" + e[0].charAt(0).toUpperCase() + e[0].slice(1);
                  this[n] = this[t], delete this[t], R(a, "Deprecated: '" + t + "' has been renamed '" + n + "'. The old method will be removed in the next major version.");
                }
              }
              var i,
                o,
                a = function (n) {
                  if ("string" != typeof n) throw new TypeError("Invaild id for iFrame. Expected String");
                  var i;
                  return "" === n && (t.id = (i = e && e.id || b.id + u++, null !== document.getElementById(i) && (i += u++), n = i), c = (e || {}).log, S(n, "Added missing iframe ID: " + n + " (" + t.src + ")")), n;
                }(t.id);
              a in v && "iFrameResizer" in t ? R(a, "Ignored iFrame, already setup.") : (!function (e) {
                var i;
                e = e || {}, v[a] = Object.create(null), v[a].iframe = t, v[a].firstRun = !0, v[a].remoteHost = t.src && t.src.split("/").slice(0, 3).join("/"), function (t) {
                  if ("object" != typeof t) throw new TypeError("Options is not an object");
                }(e), Object.keys(e).forEach(n, e), function (t) {
                  for (var e in b) Object.prototype.hasOwnProperty.call(b, e) && (v[a][e] = Object.prototype.hasOwnProperty.call(t, e) ? t[e] : b[e]);
                }(e), v[a] && (v[a].targetOrigin = !0 === v[a].checkOrigin ? "" === (i = v[a].remoteHost) || null !== i.match(/^(about:blank|javascript:|file:\/\/)/) ? "*" : i : "*");
              }(e), function () {
                switch (S(a, "IFrame scrolling " + (v[a] && v[a].scrolling ? "enabled" : "disabled") + " for " + a), t.style.overflow = !1 === (v[a] && v[a].scrolling) ? "hidden" : "auto", v[a] && v[a].scrolling) {
                  case "omit":
                    break;
                  case !0:
                    t.scrolling = "yes";
                    break;
                  case !1:
                    t.scrolling = "no";
                    break;
                  default:
                    t.scrolling = v[a] ? v[a].scrolling : "no";
                }
              }(), function () {
                function e(e) {
                  var n = v[a][e];
                  1 / 0 !== n && 0 !== n && (t.style[e] = "number" == typeof n ? n + "px" : n, S(a, "Set " + e + " = " + t.style[e]));
                }
                function n(t) {
                  if (v[a]["min" + t] > v[a]["max" + t]) throw new Error("Value for min" + t + " can not be greater than max" + t);
                }
                n("Height"), n("Width"), e("maxHeight"), e("minHeight"), e("maxWidth"), e("minWidth");
              }(), "number" != typeof (v[a] && v[a].bodyMargin) && "0" !== (v[a] && v[a].bodyMargin) || (v[a].bodyMarginV1 = v[a].bodyMargin, v[a].bodyMargin = v[a].bodyMargin + "px"), i = H(a), (o = O()) && (s = function (e) {
                if (!t.parentNode) return null;
                var n = new e(function (e) {
                  e.forEach(function (e) {
                    Array.prototype.slice.call(e.removedNodes).forEach(function (e) {
                      e === t && j(t);
                    });
                  });
                });
                return n.observe(t.parentNode, {
                  childList: !0
                }), n;
              }(o)), E(t, "load", function () {
                var e, n;
                W("iFrame.onload", i, t, r, !0), e = v[a] && v[a].firstRun, n = v[a] && v[a].heightCalculationMethod in g, !e && n && A({
                  iframe: t,
                  height: 0,
                  width: 0,
                  type: "init"
                });
              }), W("init", i, t, r, !0), v[a] && (v[a].iframe.iFrameResizer = {
                close: j.bind(null, v[a].iframe),
                removeListeners: C.bind(null, v[a].iframe),
                resize: W.bind(null, "Window resize", "resize", v[a].iframe),
                moveToAnchor: function (t) {
                  W("Move to anchor", "moveToAnchor:" + t, v[a].iframe, a);
                },
                sendMessage: function (t) {
                  W("Send Message", "message:" + (t = JSON.stringify(t)), v[a].iframe, a);
                }
              }));
            }
            function B(t, e) {
              null === y && (y = setTimeout(function () {
                y = null, t();
              }, e));
            }
            function U() {
              "hidden" !== document.visibilityState && (S("document", "Trigger event: Visibility change"), B(function () {
                V("Tab Visible", "resize");
              }, 16));
            }
            function V(t, e) {
              Object.keys(v).forEach(function (n) {
                (function (t) {
                  return v[t] && "parent" === v[t].resizeFrom && v[t].autoResize && !v[t].firstRun;
                })(n) && W(t, e, v[n].iframe, n);
              });
            }
            function q() {
              E(window, "message", M), E(window, "resize", function () {
                var t;
                S("window", "Trigger event: " + (t = "resize")), B(function () {
                  V("Window " + t, "resize");
                }, 16);
              }), E(document, "visibilitychange", U), E(document, "-webkit-visibilitychange", U);
            }
            function Y() {
              function t(t, n) {
                n && (!function () {
                  if (!n.tagName) throw new TypeError("Object is not a valid DOM element");
                  if ("IFRAME" !== n.tagName.toUpperCase()) throw new TypeError("Expected <IFRAME> tag, found <" + n.tagName + ">");
                }(), _(n, t), e.push(n));
              }
              var e;
              return function () {
                var t,
                  e = ["moz", "webkit", "o", "ms"];
                for (t = 0; t < e.length && !h; t += 1) h = window[e[t] + "RequestAnimationFrame"];
                h ? h = h.bind(window) : S("setup", "RequestAnimationFrame not supported");
              }(), q(), function (n, i) {
                switch (e = [], function (t) {
                  t && t.enablePublicMethods && R("enablePublicMethods option has been removed, public methods are now always available in the iFrame");
                }(n), typeof i) {
                  case "undefined":
                  case "string":
                    Array.prototype.forEach.call(document.querySelectorAll(i || "iframe"), t.bind(r, n));
                    break;
                  case "object":
                    t(n, i);
                    break;
                  default:
                    throw new TypeError("Unexpected data type (" + typeof i + ")");
                }
                return e;
              };
            }
          }();
        },
        6343: function (t, e, n) {
          "use strict";

          var i = n(6612);
          t.exports = i;
        },
        1015: function (t, e, n) {
          "use strict";

          var i = n(8418);
          t.exports = i;
        },
        6404: function (t, e, n) {
          "use strict";

          var i = n(9609);
          t.exports = i;
        },
        9933: function (t, e, n) {
          "use strict";

          var i = n(160);
          t.exports = i;
        },
        3609: function (t, e, n) {
          "use strict";

          n(4423);
          var i = n(7015);
          t.exports = i("Array", "includes");
        },
        8473: function (t, e, n) {
          "use strict";

          n(9085);
          var i = n(9167);
          t.exports = i.Object.assign;
        },
        4014: function (t, e, n) {
          "use strict";

          n(6034);
          var i = n(9167);
          t.exports = i.Object.values;
        },
        979: function (t, e, n) {
          "use strict";

          n(1699);
          var i = n(7015);
          t.exports = i("String", "includes");
        },
        2288: function (t, e, n) {
          "use strict";

          n(5914);
        },
        7782: function (t, e, n) {
          "use strict";

          n(6008);
        },
        8029: function (t, e, n) {
          "use strict";

          n(9311);
        },
        6396: function (t, e, n) {
          "use strict";

          n(6290);
        },
        5914: function (t, e, n) {
          "use strict";

          var i = n(6343);
          t.exports = i;
        },
        6008: function (t, e, n) {
          "use strict";

          var i = n(1015);
          t.exports = i;
        },
        9311: function (t, e, n) {
          "use strict";

          var i = n(6404);
          t.exports = i;
        },
        6290: function (t, e, n) {
          "use strict";

          var i = n(9933);
          t.exports = i;
        },
        9306: function (t, e, n) {
          "use strict";

          var i = n(4901),
            o = n(6823),
            r = TypeError;
          t.exports = function (t) {
            if (i(t)) return t;
            throw new r(o(t) + " is not a function");
          };
        },
        6469: function (t, e, n) {
          "use strict";

          var i = n(8227),
            o = n(2360),
            r = n(4913).f,
            s = i("unscopables"),
            a = Array.prototype;
          void 0 === a[s] && r(a, s, {
            configurable: !0,
            value: o(null)
          }), t.exports = function (t) {
            a[s][t] = !0;
          };
        },
        8551: function (t, e, n) {
          "use strict";

          var i = n(34),
            o = String,
            r = TypeError;
          t.exports = function (t) {
            if (i(t)) return t;
            throw new r(o(t) + " is not an object");
          };
        },
        9617: function (t, e, n) {
          "use strict";

          var i = n(5397),
            o = n(5610),
            r = n(6198),
            s = function (t) {
              return function (e, n, s) {
                var a = i(e),
                  u = r(a);
                if (0 === u) return !t && -1;
                var c,
                  f = o(s, u);
                if (t && n != n) {
                  for (; u > f;) if ((c = a[f++]) != c) return !0;
                } else for (; u > f; f++) if ((t || f in a) && a[f] === n) return t || f || 0;
                return !t && -1;
              };
            };
          t.exports = {
            includes: s(!0),
            indexOf: s(!1)
          };
        },
        4576: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = i({}.toString),
            r = i("".slice);
          t.exports = function (t) {
            return r(o(t), 8, -1);
          };
        },
        6955: function (t, e, n) {
          "use strict";

          var i = n(2140),
            o = n(4901),
            r = n(4576),
            s = n(8227)("toStringTag"),
            a = Object,
            u = "Arguments" === r(function () {
              return arguments;
            }());
          t.exports = i ? r : function (t) {
            var e, n, i;
            return void 0 === t ? "Undefined" : null === t ? "Null" : "string" == typeof (n = function (t, e) {
              try {
                return t[e];
              } catch (t) {}
            }(e = a(t), s)) ? n : u ? r(e) : "Object" === (i = r(e)) && o(e.callee) ? "Arguments" : i;
          };
        },
        7740: function (t, e, n) {
          "use strict";

          var i = n(9297),
            o = n(5031),
            r = n(7347),
            s = n(4913);
          t.exports = function (t, e, n) {
            for (var a = o(e), u = s.f, c = r.f, f = 0; f < a.length; f++) {
              var l = a[f];
              i(t, l) || n && i(n, l) || u(t, l, c(e, l));
            }
          };
        },
        1436: function (t, e, n) {
          "use strict";

          var i = n(8227)("match");
          t.exports = function (t) {
            var e = /./;
            try {
              "/./"[t](e);
            } catch (n) {
              try {
                return e[i] = !1, "/./"[t](e);
              } catch (t) {}
            }
            return !1;
          };
        },
        2211: function (t, e, n) {
          "use strict";

          var i = n(9039);
          t.exports = !i(function () {
            function t() {}
            return t.prototype.constructor = null, Object.getPrototypeOf(new t()) !== t.prototype;
          });
        },
        6699: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(4913),
            r = n(6980);
          t.exports = i ? function (t, e, n) {
            return o.f(t, e, r(1, n));
          } : function (t, e, n) {
            return t[e] = n, t;
          };
        },
        6980: function (t) {
          "use strict";

          t.exports = function (t, e) {
            return {
              enumerable: !(1 & t),
              configurable: !(2 & t),
              writable: !(4 & t),
              value: e
            };
          };
        },
        6840: function (t, e, n) {
          "use strict";

          var i = n(4901),
            o = n(4913),
            r = n(283),
            s = n(9433);
          t.exports = function (t, e, n, a) {
            a || (a = {});
            var u = a.enumerable,
              c = void 0 !== a.name ? a.name : e;
            if (i(n) && r(n, c, a), a.global) u ? t[e] = n : s(e, n);else {
              try {
                a.unsafe ? t[e] && (u = !0) : delete t[e];
              } catch (t) {}
              u ? t[e] = n : o.f(t, e, {
                value: n,
                enumerable: !1,
                configurable: !a.nonConfigurable,
                writable: !a.nonWritable
              });
            }
            return t;
          };
        },
        9433: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = Object.defineProperty;
          t.exports = function (t, e) {
            try {
              o(i, t, {
                value: e,
                configurable: !0,
                writable: !0
              });
            } catch (n) {
              i[t] = e;
            }
            return e;
          };
        },
        3724: function (t, e, n) {
          "use strict";

          var i = n(9039);
          t.exports = !i(function () {
            return 7 !== Object.defineProperty({}, 1, {
              get: function () {
                return 7;
              }
            })[1];
          });
        },
        4055: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(34),
            r = i.document,
            s = o(r) && o(r.createElement);
          t.exports = function (t) {
            return s ? r.createElement(t) : {};
          };
        },
        9392: function (t) {
          "use strict";

          t.exports = "undefined" != typeof navigator && String(navigator.userAgent) || "";
        },
        7388: function (t, e, n) {
          "use strict";

          var i,
            o,
            r = n(4475),
            s = n(9392),
            a = r.process,
            u = r.Deno,
            c = a && a.versions || u && u.version,
            f = c && c.v8;
          f && (o = (i = f.split("."))[0] > 0 && i[0] < 4 ? 1 : +(i[0] + i[1])), !o && s && (!(i = s.match(/Edge\/(\d+)/)) || i[1] >= 74) && (i = s.match(/Chrome\/(\d+)/)) && (o = +i[1]), t.exports = o;
        },
        7015: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(9504);
          t.exports = function (t, e) {
            return o(i[t].prototype[e]);
          };
        },
        8727: function (t) {
          "use strict";

          t.exports = ["constructor", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "toLocaleString", "toString", "valueOf"];
        },
        6518: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(7347).f,
            r = n(6699),
            s = n(6840),
            a = n(9433),
            u = n(7740),
            c = n(2796);
          t.exports = function (t, e) {
            var n,
              f,
              l,
              d,
              m,
              p = t.target,
              h = t.global,
              g = t.stat;
            if (n = h ? i : g ? i[p] || a(p, {}) : i[p] && i[p].prototype) for (f in e) {
              if (d = e[f], l = t.dontCallGetSet ? (m = o(n, f)) && m.value : n[f], !c(h ? f : p + (g ? "." : "#") + f, t.forced) && void 0 !== l) {
                if (typeof d == typeof l) continue;
                u(d, l);
              }
              (t.sham || l && l.sham) && r(d, "sham", !0), s(n, f, d, t);
            }
          };
        },
        9039: function (t) {
          "use strict";

          t.exports = function (t) {
            try {
              return !!t();
            } catch (t) {
              return !0;
            }
          };
        },
        616: function (t, e, n) {
          "use strict";

          var i = n(9039);
          t.exports = !i(function () {
            var t = function () {}.bind();
            return "function" != typeof t || t.hasOwnProperty("prototype");
          });
        },
        9565: function (t, e, n) {
          "use strict";

          var i = n(616),
            o = Function.prototype.call;
          t.exports = i ? o.bind(o) : function () {
            return o.apply(o, arguments);
          };
        },
        350: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9297),
            r = Function.prototype,
            s = i && Object.getOwnPropertyDescriptor,
            a = o(r, "name"),
            u = a && "something" === function () {}.name,
            c = a && (!i || i && s(r, "name").configurable);
          t.exports = {
            EXISTS: a,
            PROPER: u,
            CONFIGURABLE: c
          };
        },
        9504: function (t, e, n) {
          "use strict";

          var i = n(616),
            o = Function.prototype,
            r = o.call,
            s = i && o.bind.bind(r, r);
          t.exports = i ? s : function (t) {
            return function () {
              return r.apply(t, arguments);
            };
          };
        },
        7751: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(4901);
          t.exports = function (t, e) {
            return arguments.length < 2 ? (n = i[t], o(n) ? n : void 0) : i[t] && i[t][e];
            var n;
          };
        },
        5966: function (t, e, n) {
          "use strict";

          var i = n(9306),
            o = n(4117);
          t.exports = function (t, e) {
            var n = t[e];
            return o(n) ? void 0 : i(n);
          };
        },
        4475: function (t, e, n) {
          "use strict";

          var i = function (t) {
            return t && t.Math === Math && t;
          };
          t.exports = i("object" == typeof globalThis && globalThis) || i("object" == typeof window && window) || i("object" == typeof self && self) || i("object" == typeof n.g && n.g) || i("object" == typeof this && this) || function () {
            return this;
          }() || Function("return this")();
        },
        9297: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = n(8981),
            r = i({}.hasOwnProperty);
          t.exports = Object.hasOwn || function (t, e) {
            return r(o(t), e);
          };
        },
        421: function (t) {
          "use strict";

          t.exports = {};
        },
        397: function (t, e, n) {
          "use strict";

          var i = n(7751);
          t.exports = i("document", "documentElement");
        },
        5917: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9039),
            r = n(4055);
          t.exports = !i && !o(function () {
            return 7 !== Object.defineProperty(r("div"), "a", {
              get: function () {
                return 7;
              }
            }).a;
          });
        },
        7055: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = n(9039),
            r = n(4576),
            s = Object,
            a = i("".split);
          t.exports = o(function () {
            return !s("z").propertyIsEnumerable(0);
          }) ? function (t) {
            return "String" === r(t) ? a(t, "") : s(t);
          } : s;
        },
        3706: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = n(4901),
            r = n(7629),
            s = i(Function.toString);
          o(r.inspectSource) || (r.inspectSource = function (t) {
            return s(t);
          }), t.exports = r.inspectSource;
        },
        1181: function (t, e, n) {
          "use strict";

          var i,
            o,
            r,
            s = n(8622),
            a = n(4475),
            u = n(34),
            c = n(6699),
            f = n(9297),
            l = n(7629),
            d = n(6119),
            m = n(421),
            p = "Object already initialized",
            h = a.TypeError,
            g = a.WeakMap;
          if (s || l.state) {
            var v = l.state || (l.state = new g());
            v.get = v.get, v.has = v.has, v.set = v.set, i = function (t, e) {
              if (v.has(t)) throw new h(p);
              return e.facade = t, v.set(t, e), e;
            }, o = function (t) {
              return v.get(t) || {};
            }, r = function (t) {
              return v.has(t);
            };
          } else {
            var y = d("state");
            m[y] = !0, i = function (t, e) {
              if (f(t, y)) throw new h(p);
              return e.facade = t, c(t, y, e), e;
            }, o = function (t) {
              return f(t, y) ? t[y] : {};
            }, r = function (t) {
              return f(t, y);
            };
          }
          t.exports = {
            set: i,
            get: o,
            has: r,
            enforce: function (t) {
              return r(t) ? o(t) : i(t, {});
            },
            getterFor: function (t) {
              return function (e) {
                var n;
                if (!u(e) || (n = o(e)).type !== t) throw new h("Incompatible receiver, " + t + " required");
                return n;
              };
            }
          };
        },
        4901: function (t) {
          "use strict";

          var e = "object" == typeof document && document.all;
          t.exports = void 0 === e && void 0 !== e ? function (t) {
            return "function" == typeof t || t === e;
          } : function (t) {
            return "function" == typeof t;
          };
        },
        2796: function (t, e, n) {
          "use strict";

          var i = n(9039),
            o = n(4901),
            r = /#|\.prototype\./,
            s = function (t, e) {
              var n = u[a(t)];
              return n === f || n !== c && (o(e) ? i(e) : !!e);
            },
            a = s.normalize = function (t) {
              return String(t).replace(r, ".").toLowerCase();
            },
            u = s.data = {},
            c = s.NATIVE = "N",
            f = s.POLYFILL = "P";
          t.exports = s;
        },
        4117: function (t) {
          "use strict";

          t.exports = function (t) {
            return null == t;
          };
        },
        34: function (t, e, n) {
          "use strict";

          var i = n(4901);
          t.exports = function (t) {
            return "object" == typeof t ? null !== t : i(t);
          };
        },
        6395: function (t) {
          "use strict";

          t.exports = !1;
        },
        788: function (t, e, n) {
          "use strict";

          var i = n(34),
            o = n(4576),
            r = n(8227)("match");
          t.exports = function (t) {
            var e;
            return i(t) && (void 0 !== (e = t[r]) ? !!e : "RegExp" === o(t));
          };
        },
        757: function (t, e, n) {
          "use strict";

          var i = n(7751),
            o = n(4901),
            r = n(1625),
            s = n(7040),
            a = Object;
          t.exports = s ? function (t) {
            return "symbol" == typeof t;
          } : function (t) {
            var e = i("Symbol");
            return o(e) && r(e.prototype, a(t));
          };
        },
        6198: function (t, e, n) {
          "use strict";

          var i = n(8014);
          t.exports = function (t) {
            return i(t.length);
          };
        },
        283: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = n(9039),
            r = n(4901),
            s = n(9297),
            a = n(3724),
            u = n(350).CONFIGURABLE,
            c = n(3706),
            f = n(1181),
            l = f.enforce,
            d = f.get,
            m = String,
            p = Object.defineProperty,
            h = i("".slice),
            g = i("".replace),
            v = i([].join),
            y = a && !o(function () {
              return 8 !== p(function () {}, "length", {
                value: 8
              }).length;
            }),
            b = String(String).split("String"),
            w = t.exports = function (t, e, n) {
              "Symbol(" === h(m(e), 0, 7) && (e = "[" + g(m(e), /^Symbol\(([^)]*)\).*$/, "$1") + "]"), n && n.getter && (e = "get " + e), n && n.setter && (e = "set " + e), (!s(t, "name") || u && t.name !== e) && (a ? p(t, "name", {
                value: e,
                configurable: !0
              }) : t.name = e), y && n && s(n, "arity") && t.length !== n.arity && p(t, "length", {
                value: n.arity
              });
              try {
                n && s(n, "constructor") && n.constructor ? a && p(t, "prototype", {
                  writable: !1
                }) : t.prototype && (t.prototype = void 0);
              } catch (t) {}
              var i = l(t);
              return s(i, "source") || (i.source = v(b, "string" == typeof e ? e : "")), t;
            };
          Function.prototype.toString = w(function () {
            return r(this) && d(this).source || c(this);
          }, "toString");
        },
        741: function (t) {
          "use strict";

          var e = Math.ceil,
            n = Math.floor;
          t.exports = Math.trunc || function (t) {
            var i = +t;
            return (i > 0 ? n : e)(i);
          };
        },
        511: function (t, e, n) {
          "use strict";

          var i = n(788),
            o = TypeError;
          t.exports = function (t) {
            if (i(t)) throw new o("The method doesn't accept regular expressions");
            return t;
          };
        },
        4213: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9504),
            r = n(9565),
            s = n(9039),
            a = n(1072),
            u = n(3717),
            c = n(8773),
            f = n(8981),
            l = n(7055),
            d = Object.assign,
            m = Object.defineProperty,
            p = o([].concat);
          t.exports = !d || s(function () {
            if (i && 1 !== d({
              b: 1
            }, d(m({}, "a", {
              enumerable: !0,
              get: function () {
                m(this, "b", {
                  value: 3,
                  enumerable: !1
                });
              }
            }), {
              b: 2
            })).b) return !0;
            var t = {},
              e = {},
              n = Symbol("assign detection"),
              o = "abcdefghijklmnopqrst";
            return t[n] = 7, o.split("").forEach(function (t) {
              e[t] = t;
            }), 7 !== d({}, t)[n] || a(d({}, e)).join("") !== o;
          }) ? function (t, e) {
            for (var n = f(t), o = arguments.length, s = 1, d = u.f, m = c.f; o > s;) for (var h, g = l(arguments[s++]), v = d ? p(a(g), d(g)) : a(g), y = v.length, b = 0; y > b;) h = v[b++], i && !r(m, g, h) || (n[h] = g[h]);
            return n;
          } : d;
        },
        2360: function (t, e, n) {
          "use strict";

          var i,
            o = n(8551),
            r = n(6801),
            s = n(8727),
            a = n(421),
            u = n(397),
            c = n(4055),
            f = n(6119),
            l = "prototype",
            d = "script",
            m = f("IE_PROTO"),
            p = function () {},
            h = function (t) {
              return "<" + d + ">" + t + "</" + d + ">";
            },
            g = function (t) {
              t.write(h("")), t.close();
              var e = t.parentWindow.Object;
              return t = null, e;
            },
            v = function () {
              try {
                i = new ActiveXObject("htmlfile");
              } catch (t) {}
              var t, e, n;
              v = "undefined" != typeof document ? document.domain && i ? g(i) : (e = c("iframe"), n = "java" + d + ":", e.style.display = "none", u.appendChild(e), e.src = String(n), (t = e.contentWindow.document).open(), t.write(h("document.F=Object")), t.close(), t.F) : g(i);
              for (var o = s.length; o--;) delete v[l][s[o]];
              return v();
            };
          a[m] = !0, t.exports = Object.create || function (t, e) {
            var n;
            return null !== t ? (p[l] = o(t), n = new p(), p[l] = null, n[m] = t) : n = v(), void 0 === e ? n : r.f(n, e);
          };
        },
        6801: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(8686),
            r = n(4913),
            s = n(8551),
            a = n(5397),
            u = n(1072);
          e.f = i && !o ? Object.defineProperties : function (t, e) {
            s(t);
            for (var n, i = a(e), o = u(e), c = o.length, f = 0; c > f;) r.f(t, n = o[f++], i[n]);
            return t;
          };
        },
        4913: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(5917),
            r = n(8686),
            s = n(8551),
            a = n(6969),
            u = TypeError,
            c = Object.defineProperty,
            f = Object.getOwnPropertyDescriptor,
            l = "enumerable",
            d = "configurable",
            m = "writable";
          e.f = i ? r ? function (t, e, n) {
            if (s(t), e = a(e), s(n), "function" == typeof t && "prototype" === e && "value" in n && m in n && !n[m]) {
              var i = f(t, e);
              i && i[m] && (t[e] = n.value, n = {
                configurable: d in n ? n[d] : i[d],
                enumerable: l in n ? n[l] : i[l],
                writable: !1
              });
            }
            return c(t, e, n);
          } : c : function (t, e, n) {
            if (s(t), e = a(e), s(n), o) try {
              return c(t, e, n);
            } catch (t) {}
            if ("get" in n || "set" in n) throw new u("Accessors not supported");
            return "value" in n && (t[e] = n.value), t;
          };
        },
        7347: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9565),
            r = n(8773),
            s = n(6980),
            a = n(5397),
            u = n(6969),
            c = n(9297),
            f = n(5917),
            l = Object.getOwnPropertyDescriptor;
          e.f = i ? l : function (t, e) {
            if (t = a(t), e = u(e), f) try {
              return l(t, e);
            } catch (t) {}
            if (c(t, e)) return s(!o(r.f, t, e), t[e]);
          };
        },
        8480: function (t, e, n) {
          "use strict";

          var i = n(1828),
            o = n(8727).concat("length", "prototype");
          e.f = Object.getOwnPropertyNames || function (t) {
            return i(t, o);
          };
        },
        3717: function (t, e) {
          "use strict";

          e.f = Object.getOwnPropertySymbols;
        },
        2787: function (t, e, n) {
          "use strict";

          var i = n(9297),
            o = n(4901),
            r = n(8981),
            s = n(6119),
            a = n(2211),
            u = s("IE_PROTO"),
            c = Object,
            f = c.prototype;
          t.exports = a ? c.getPrototypeOf : function (t) {
            var e = r(t);
            if (i(e, u)) return e[u];
            var n = e.constructor;
            return o(n) && e instanceof n ? n.prototype : e instanceof c ? f : null;
          };
        },
        1625: function (t, e, n) {
          "use strict";

          var i = n(9504);
          t.exports = i({}.isPrototypeOf);
        },
        1828: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = n(9297),
            r = n(5397),
            s = n(9617).indexOf,
            a = n(421),
            u = i([].push);
          t.exports = function (t, e) {
            var n,
              i = r(t),
              c = 0,
              f = [];
            for (n in i) !o(a, n) && o(i, n) && u(f, n);
            for (; e.length > c;) o(i, n = e[c++]) && (~s(f, n) || u(f, n));
            return f;
          };
        },
        1072: function (t, e, n) {
          "use strict";

          var i = n(1828),
            o = n(8727);
          t.exports = Object.keys || function (t) {
            return i(t, o);
          };
        },
        8773: function (t, e) {
          "use strict";

          var n = {}.propertyIsEnumerable,
            i = Object.getOwnPropertyDescriptor,
            o = i && !n.call({
              1: 2
            }, 1);
          e.f = o ? function (t) {
            var e = i(this, t);
            return !!e && e.enumerable;
          } : n;
        },
        2357: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9039),
            r = n(9504),
            s = n(2787),
            a = n(1072),
            u = n(5397),
            c = r(n(8773).f),
            f = r([].push),
            l = i && o(function () {
              var t = Object.create(null);
              return t[2] = 2, !c(t, 2);
            }),
            d = function (t) {
              return function (e) {
                for (var n, o = u(e), r = a(o), d = l && null === s(o), m = r.length, p = 0, h = []; m > p;) n = r[p++], i && !(d ? n in o : c(o, n)) || f(h, t ? [n, o[n]] : o[n]);
                return h;
              };
            };
          t.exports = {
            entries: d(!0),
            values: d(!1)
          };
        },
        4270: function (t, e, n) {
          "use strict";

          var i = n(9565),
            o = n(4901),
            r = n(34),
            s = TypeError;
          t.exports = function (t, e) {
            var n, a;
            if ("string" === e && o(n = t.toString) && !r(a = i(n, t))) return a;
            if (o(n = t.valueOf) && !r(a = i(n, t))) return a;
            if ("string" !== e && o(n = t.toString) && !r(a = i(n, t))) return a;
            throw new s("Can't convert object to primitive value");
          };
        },
        5031: function (t, e, n) {
          "use strict";

          var i = n(7751),
            o = n(9504),
            r = n(8480),
            s = n(3717),
            a = n(8551),
            u = o([].concat);
          t.exports = i("Reflect", "ownKeys") || function (t) {
            var e = r.f(a(t)),
              n = s.f;
            return n ? u(e, n(t)) : e;
          };
        },
        9167: function (t, e, n) {
          "use strict";

          var i = n(4475);
          t.exports = i;
        },
        7750: function (t, e, n) {
          "use strict";

          var i = n(4117),
            o = TypeError;
          t.exports = function (t) {
            if (i(t)) throw new o("Can't call method on " + t);
            return t;
          };
        },
        6119: function (t, e, n) {
          "use strict";

          var i = n(5745),
            o = n(3392),
            r = i("keys");
          t.exports = function (t) {
            return r[t] || (r[t] = o(t));
          };
        },
        7629: function (t, e, n) {
          "use strict";

          var i = n(6395),
            o = n(4475),
            r = n(9433),
            s = "__core-js_shared__",
            a = t.exports = o[s] || r(s, {});
          (a.versions || (a.versions = [])).push({
            version: "3.37.1",
            mode: i ? "pure" : "global",
            copyright: "© 2014-2024 Denis Pushkarev (zloirock.ru)",
            license: "https://github.com/zloirock/core-js/blob/v3.37.1/LICENSE",
            source: "https://github.com/zloirock/core-js"
          });
        },
        5745: function (t, e, n) {
          "use strict";

          var i = n(7629);
          t.exports = function (t, e) {
            return i[t] || (i[t] = e || {});
          };
        },
        4495: function (t, e, n) {
          "use strict";

          var i = n(7388),
            o = n(9039),
            r = n(4475).String;
          t.exports = !!Object.getOwnPropertySymbols && !o(function () {
            var t = Symbol("symbol detection");
            return !r(t) || !(Object(t) instanceof Symbol) || !Symbol.sham && i && i < 41;
          });
        },
        5610: function (t, e, n) {
          "use strict";

          var i = n(1291),
            o = Math.max,
            r = Math.min;
          t.exports = function (t, e) {
            var n = i(t);
            return n < 0 ? o(n + e, 0) : r(n, e);
          };
        },
        5397: function (t, e, n) {
          "use strict";

          var i = n(7055),
            o = n(7750);
          t.exports = function (t) {
            return i(o(t));
          };
        },
        1291: function (t, e, n) {
          "use strict";

          var i = n(741);
          t.exports = function (t) {
            var e = +t;
            return e != e || 0 === e ? 0 : i(e);
          };
        },
        8014: function (t, e, n) {
          "use strict";

          var i = n(1291),
            o = Math.min;
          t.exports = function (t) {
            var e = i(t);
            return e > 0 ? o(e, 9007199254740991) : 0;
          };
        },
        8981: function (t, e, n) {
          "use strict";

          var i = n(7750),
            o = Object;
          t.exports = function (t) {
            return o(i(t));
          };
        },
        2777: function (t, e, n) {
          "use strict";

          var i = n(9565),
            o = n(34),
            r = n(757),
            s = n(5966),
            a = n(4270),
            u = n(8227),
            c = TypeError,
            f = u("toPrimitive");
          t.exports = function (t, e) {
            if (!o(t) || r(t)) return t;
            var n,
              u = s(t, f);
            if (u) {
              if (void 0 === e && (e = "default"), n = i(u, t, e), !o(n) || r(n)) return n;
              throw new c("Can't convert object to primitive value");
            }
            return void 0 === e && (e = "number"), a(t, e);
          };
        },
        6969: function (t, e, n) {
          "use strict";

          var i = n(2777),
            o = n(757);
          t.exports = function (t) {
            var e = i(t, "string");
            return o(e) ? e : e + "";
          };
        },
        2140: function (t, e, n) {
          "use strict";

          var i = {};
          i[n(8227)("toStringTag")] = "z", t.exports = "[object z]" === String(i);
        },
        655: function (t, e, n) {
          "use strict";

          var i = n(6955),
            o = String;
          t.exports = function (t) {
            if ("Symbol" === i(t)) throw new TypeError("Cannot convert a Symbol value to a string");
            return o(t);
          };
        },
        6823: function (t) {
          "use strict";

          var e = String;
          t.exports = function (t) {
            try {
              return e(t);
            } catch (t) {
              return "Object";
            }
          };
        },
        3392: function (t, e, n) {
          "use strict";

          var i = n(9504),
            o = 0,
            r = Math.random(),
            s = i(1..toString);
          t.exports = function (t) {
            return "Symbol(" + (void 0 === t ? "" : t) + ")_" + s(++o + r, 36);
          };
        },
        7040: function (t, e, n) {
          "use strict";

          var i = n(4495);
          t.exports = i && !Symbol.sham && "symbol" == typeof Symbol.iterator;
        },
        8686: function (t, e, n) {
          "use strict";

          var i = n(3724),
            o = n(9039);
          t.exports = i && o(function () {
            return 42 !== Object.defineProperty(function () {}, "prototype", {
              value: 42,
              writable: !1
            }).prototype;
          });
        },
        8622: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(4901),
            r = i.WeakMap;
          t.exports = o(r) && /native code/.test(String(r));
        },
        8227: function (t, e, n) {
          "use strict";

          var i = n(4475),
            o = n(5745),
            r = n(9297),
            s = n(3392),
            a = n(4495),
            u = n(7040),
            c = i.Symbol,
            f = o("wks"),
            l = u ? c.for || c : c && c.withoutSetter || s;
          t.exports = function (t) {
            return r(f, t) || (f[t] = a && r(c, t) ? c[t] : l("Symbol." + t)), f[t];
          };
        },
        4423: function (t, e, n) {
          "use strict";

          var i = n(6518),
            o = n(9617).includes,
            r = n(9039),
            s = n(6469);
          i({
            target: "Array",
            proto: !0,
            forced: r(function () {
              return !Array(1).includes();
            })
          }, {
            includes: function (t) {
              return o(this, t, arguments.length > 1 ? arguments[1] : void 0);
            }
          }), s("includes");
        },
        9085: function (t, e, n) {
          "use strict";

          var i = n(6518),
            o = n(4213);
          i({
            target: "Object",
            stat: !0,
            arity: 2,
            forced: Object.assign !== o
          }, {
            assign: o
          });
        },
        6034: function (t, e, n) {
          "use strict";

          var i = n(6518),
            o = n(2357).values;
          i({
            target: "Object",
            stat: !0
          }, {
            values: function (t) {
              return o(t);
            }
          });
        },
        1699: function (t, e, n) {
          "use strict";

          var i = n(6518),
            o = n(9504),
            r = n(511),
            s = n(7750),
            a = n(655),
            u = n(1436),
            c = o("".indexOf);
          i({
            target: "String",
            proto: !0,
            forced: !u("includes")
          }, {
            includes: function (t) {
              return !!~c(a(s(this)), a(r(t)), arguments.length > 1 ? arguments[1] : void 0);
            }
          });
        },
        6612: function (t, e, n) {
          "use strict";

          var i = n(3609);
          t.exports = i;
        },
        8418: function (t, e, n) {
          "use strict";

          var i = n(8473);
          t.exports = i;
        },
        9609: function (t, e, n) {
          "use strict";

          var i = n(4014);
          t.exports = i;
        },
        160: function (t, e, n) {
          "use strict";

          var i = n(979);
          t.exports = i;
        }
      },
      e = {};
    function n(i) {
      var o = e[i];
      if (void 0 !== o) return o.exports;
      var r = e[i] = {
        exports: {}
      };
      return t[i].call(r.exports, r, r.exports, n), r.exports;
    }
    n.d = function (t, e) {
      for (var i in e) n.o(e, i) && !n.o(t, i) && Object.defineProperty(t, i, {
        enumerable: !0,
        get: e[i]
      });
    }, n.g = function () {
      if ("object" == typeof globalThis) return globalThis;
      try {
        return this || new Function("return this")();
      } catch (t) {
        if ("object" == typeof window) return window;
      }
    }(), n.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, n.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    };
    var i = {};
    return function () {
      "use strict";

      n.r(i), n.d(i, {
        ConvertBoundaryMessage: function () {
          return r;
        },
        DigitaServiceError: function () {
          return o;
        },
        FRAME_EVENT_TYPES: function () {
          return s;
        },
        FRAME_EVENT_TYPES_ARRAY: function () {
          return a;
        },
        FRAME_RECEIPT_TYPES: function () {
          return f;
        },
        FRAME_RECEIPT_TYPES_ARRAY: function () {
          return l;
        },
        Frame: function () {
          return u;
        },
        MINIMUM_FRAME_HEIGHT: function () {
          return m;
        },
        QueryParams: function () {
          return d;
        },
        TextAnimationTypes: function () {
          return c;
        },
        UtilsVersion: function () {
          return p;
        },
        canvasLocalToGlobal: function () {
          return t;
        },
        canvasTouchLocalToGlobal: function () {
          return e;
        }
      });
      n(6396), n(2288), n(7782), n(8029), n(4126);
      const t = (t, e, n) => {
          if (!t) return {
            x: "0",
            y: "0"
          };
          const i = e - t.offsetLeft,
            o = n - t.offsetTop,
            r = i * t.width,
            s = o * t.height,
            a = r / t.clientWidth,
            u = s / t.clientHeight,
            c = a / t.width,
            f = u / t.height,
            l = Math.floor(100 * c) || 0,
            d = Math.floor(100 * f) || 0;
          let m = l.toString() + "%",
            p = d.toString() + "%";
          0 === l && (m = "0"), 0 === d && (p = "0");
          return {
            x: m,
            y: p
          };
        },
        e = (e, n) => n && e ? t(e, n.clientX || 0, n.clientY || 0) : {
          x: "0",
          y: "0"
        };
      class o extends Error {
        constructor(t = "Error", e = "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20128%20128%22%20xmlns%3Av%3D%22https%3A%2F%2Fvecta.io%2Fnano%22%3E%3Cpath%20d%3D%22M64%200C28.6%200%200%2028.6%200%2064s28.6%2064%2064%2064%2064-28.6%2064-64S99.4%200%2064%200zm0%20119.7c-30.7%200-55.7-25-55.7-55.7S33.3%208.3%2064%208.3s55.7%2025%2055.7%2055.7-25%2055.7-55.7%2055.7zm0-39.2c-11.5%200-22.2%205.1-29.6%2013.9-1.5%201.8-1.2%204.4.5%205.8%201.8%201.5%204.4%201.2%205.8-.5C46.5%2092.8%2055%2088.8%2064%2088.8s17.5%204%2023.3%2010.9c1.4%201.7%204%202.1%205.8.5%201.8-1.5%202-4.1.5-5.8-7.4-8.8-18.1-13.9-29.6-13.9zM43.4%2059.9a8.28%208.28%200%201%200%200-16.6%208.28%208.28%200%201%200%200%2016.6zm41.2%200a8.28%208.28%200%201%200%200-16.6%208.28%208.28%200%200%200-8.3%208.3c0%204.6%203.8%208.3%208.3%208.3z%22%20fill%3D%22%23d32f2f%22%2F%3E%3C%2Fsvg%3E", ...n) {
          super(...n), this.message = t, this.image = e, Error.captureStackTrace && Error.captureStackTrace(this, o), this.name = "DigitaServiceError";
        }
      }
      const r = (t, e) => {
        if (t && t.message) {
          const n = t.message,
            i = n.isDigitaService,
            o = n.isPlugin,
            r = n.type,
            s = n.data,
            a = n.id;
          return i ? o ? e ? {
            data: s,
            id: a,
            type: r
          } : void 0 : e ? void 0 : {
            data: s,
            id: a,
            type: r
          } : void 0;
        }
      };
      var s;
      !function (t) {
        t.COMPLETE = "complete", t.ERROR = "error", t.READY = "ready", t.RESIZE = "resize", t.SCROLL_TO_TOP = "scrolltotop", t.CLOSE = "close", t.FIRST_INTERACTION = "firstinteraction", t.ROUTE_CHANGE = "routechange", t.DIALOG = "dialog", t.SNACKBAR = "snackbar", t.TEXT = "text", t.EFFECT = "effect", t.DB_SCORE = "databoxScore", t.DB_HIGHSCORE = "databoxHighScore", t.DB_TIMER = "databoxTimer", t.DB_PROGRESS_NUMERIC = "databoxProgressNumeric", t.DB_PROGRESS_ICON = "databoxProgressIcon", t.DB_ATTEMPTS_ICON = "databoxAttemptsIcon", t.DB_LIVES_ICON = "databoxLivesIcon";
      }(s || (s = {}));
      const a = Object.values(s);
      class u {
        constructor(t) {
          if (this.options = t, this.onIFrameLoaded = () => {
            var t, e, n, i, o, r, s, a, u, c, f, l, d, p, h, g, v, y;
            const b = this.options.iframe,
              w = 999999999 * Math.random();
            window.iFrameResize({
              autoResize: (null === (e = null === (t = this.options) || void 0 === t ? void 0 : t.settings) || void 0 === e ? void 0 : e.autoResize) || !0,
              checkOrigin: (null === (i = null === (n = this.options) || void 0 === n ? void 0 : n.settings) || void 0 === i ? void 0 : i.checkOrigin) || !1,
              heightCalculationMethod: (null === (r = null === (o = this.options) || void 0 === o ? void 0 : o.settings) || void 0 === r ? void 0 : r.heightCalculationMethod) || "taggedElement",
              id: "digita-widget-" + w.toString(),
              log: (null === (a = null === (s = this.options) || void 0 === s ? void 0 : s.settings) || void 0 === a ? void 0 : a.log) || !1,
              minHeight: (null === (c = null === (u = this.options) || void 0 === u ? void 0 : u.settings) || void 0 === c ? void 0 : c.minHeight) || m,
              onInit: this.onIFrameResizerInit,
              onMessage: this.onIFrameResizerMessage,
              onResized: this.onIFrameResizerResize,
              scrolling: (null === (l = null === (f = this.options) || void 0 === f ? void 0 : f.settings) || void 0 === l ? void 0 : l.scrolling) || !1,
              sizeHeight: (null === (p = null === (d = this.options) || void 0 === d ? void 0 : d.settings) || void 0 === p ? void 0 : p.sizeHeight) || !0,
              sizeWidth: (null === (g = null === (h = this.options) || void 0 === h ? void 0 : h.settings) || void 0 === g ? void 0 : g.sizeWidth) || !1,
              tolerance: (null === (y = null === (v = this.options) || void 0 === v ? void 0 : v.settings) || void 0 === y ? void 0 : y.tolerance) || 2
            }, b);
          }, this.onIFrameError = t => {
            const e = {
              data: new o(t.message),
              type: s.ERROR
            };
            this.dispatchErrorEvent(e);
          }, this.onIFrameResizerInit = t => {
            t === this.options.iframe && this.toggleVisibility(!0);
          }, this.onIFrameResizerMessage = t => {
            const e = r(t, this.options.forPlugin || !1);
            if (e) switch (e.type) {
              default:
                this.dispatchEvent(e);
                break;
              case s.READY:
                this.dispatchReadyEvent(e);
                break;
              case s.COMPLETE:
                this.dispatchCompleteEvent(e);
                break;
              case s.CLOSE:
                this.dispatchCloseEvent();
                break;
              case s.RESIZE:
                this.dispatchResizeEvent(e);
                break;
              case s.SCROLL_TO_TOP:
                this.dispatchScrollToTopEvent(e);
                break;
              case s.ERROR:
                this.dispatchErrorEvent(e);
                break;
              case s.FIRST_INTERACTION:
                this.dispatchFirstInteractionEvent();
                break;
              case s.ROUTE_CHANGE:
                this.dispatchRouteChangeEvent(e);
            }
          }, this.onIFrameResizerResize = t => {
            var e, n;
            const i = this.options.iframe;
            if (!1 !== (null === (n = null === (e = this.options) || void 0 === e ? void 0 : e.settings) || void 0 === n ? void 0 : n.childControlsHeight) && t && t.iframe === i && t.height) {
              const e = Math.floor(t.height);
              i.style.height = `${e}px`, this.dispatchResizeEvent({
                data: {
                  height: e
                },
                type: s.RESIZE
              });
            }
          }, !t.iframe || !t.url) throw new Error("[DigitaFrame] You must supply an options.iframe HTMLIFrameElement and a options.url string absolute URL");
          this.toggleVisibility(!1), this.toggleInput(!1), t.iframe.style.overflow = "hidden", t.iframe.style.border = "0", t.iframe.style.margin = "0", t.iframe.style.padding = "0", t.iframe.style.minHeight = `${m}px`, t.iframe.style.height = "auto";
        }
        focus() {
          const t = this.options.iframe;
          t && t.focus();
        }
        load() {
          const t = this.options.iframe;
          t.addEventListener("load", this.onIFrameLoaded), t.addEventListener("error", this.onIFrameError), t.src = this.options.url;
        }
        resize() {
          const t = this.options.iframe;
          t && t.iFrameResizer && t.iFrameResizer.resize();
        }
        sendReceipt(t) {
          const e = this.options.iframe;
          e && e.iFrameResizer && t && e.iFrameResizer.sendMessage(t);
        }
        toggleVisibility(t) {
          const e = this.options.iframe;
          e && (e.style.opacity = t ? "1" : "0");
        }
        toggleInput(t) {
          const e = this.options.iframe;
          e && (e.style.pointerEvents = t ? "auto" : "none");
        }
        destroy() {
          const t = this.options.iframe;
          t.iFrameResizer && t.iFrameResizer.close(), this.toggleVisibility(!0), this.toggleInput(!0), t.removeEventListener("load", this.onIFrameLoaded), t.removeEventListener("error", this.onIFrameError);
        }
        dispatchReadyEvent(t) {
          this.toggleInput(!0), this.onReady && this.onReady(t);
        }
        dispatchCompleteEvent(t) {
          this.onComplete && this.onComplete(t);
        }
        dispatchCloseEvent() {
          this.onClose && this.onClose();
        }
        dispatchScrollToTopEvent(t) {
          this.onScrollToTop && this.onScrollToTop(t);
        }
        dispatchFirstInteractionEvent() {
          this.onFirstInteraction && this.onFirstInteraction();
        }
        dispatchRouteChangeEvent(t) {
          this.onRouteChange && this.onRouteChange(t);
        }
        dispatchResizeEvent(t) {
          this.onResize && this.onResize(t);
        }
        dispatchEvent(t) {
          this.onEvent && this.onEvent(t);
        }
        dispatchErrorEvent(t) {
          this.onError && this.onError(t);
        }
      }
      var c, f;
      !function (t) {
        t.bounceInOut = "bounceInOut", t.fadeInOut = "fadeInOut", t.slideInOutFromLeft = "slideInOutFromLeft", t.slideInOutFromRight = "slideInOutFromRight", t.slideInOutFromTop = "slideInOutFromTop", t.slideInOutFromBottom = "slideInOutFromBottom", t.slideInOutFromLeftReversed = "slideInOutFromLeftReversed", t.slideInOutFromRightReversed = "slideInOutFromRightReversed", t.slideInOutFromTopReversed = "slideInOutFromTopReversed", t.slideInOutFromBottomReversed = "slideInOutFromBottomReversed", t.zoomInOut = "zoomInOut", t.zoomInOutTowards = "zoomInOutTowards", t.rollInOut = "rollInOut";
      }(c || (c = {})), function (t) {
        t.DIALOG = "dialog", t.SNACKBAR = "snackbar", t.TEXT = "text";
      }(f || (f = {}));
      const l = Object.values(f);
      class d {
        constructor(t) {
          if (this.url = "", this.data = {}, !this.normalizeTarget) throw new Error('Use "new Digita.Utils.QueryParams()" since it is a class');
          (t = this.normalizeTarget(t)) && (t.includes("#") && (t = t.substring(0, t.indexOf("#"))), this.url = this.getDomainSegment(t), this.data = this.getURLParameters(t));
        }
        setURL(t) {
          this.url = t;
        }
        setData(t) {
          t && Object.assign(this.data, t);
        }
        removeKey(t) {
          this.data[t] && delete this.data[t];
        }
        getKey(t, e) {
          const n = this.data[t];
          return n && n.length > 0 ? n : e || void 0;
        }
        hasKey(t) {
          if (!t) return !1;
          let e = !1;
          return Object.keys(this.data).forEach(n => {
            n === t && (e = !0);
          }), e;
        }
        generateLink(t = !1) {
          let e = "";
          if (Object.keys(this.data).forEach(t => {
            e += t, e += "=", e += encodeURIComponent(this.data[t]), e += "&";
          }), t) return e.length > 0 && (e = e.substring(0, e.length - 1)), e;
          const n = this.url;
          return e.length > 0 ? (e = e.substring(0, e.length - 1), `${n}?${e}`) : n;
        }
        normalizeTarget(t) {
          return t || (window ? window.location.href : "");
        }
        getURLParameters(t) {
          const e = {};
          if (t && -1 !== t.indexOf("?")) {
            let n = t;
            if (n = n.substring(n.indexOf("?") + 1), 0 === n.length) return e;
            const i = n.split("&");
            for (const t of i) {
              const n = t.split("="),
                i = n[0];
              let o = n[1];
              o = o ? decodeURIComponent(o) : "true", e[i] = o;
            }
          }
          return e;
        }
        getDomainSegment(t) {
          let e = t || "";
          return t && -1 !== t.indexOf("?") && (e = t.split("?")[0]), e;
        }
      }
      const m = 0,
        p = `😄 Version 3.4.1 created ${new Date().toString()} 😄`;
    }(), i;
  }();
});
