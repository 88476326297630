import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DigitaServiceError } from '@digitaservice/utils';
import { take } from 'rxjs/operators';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { ISnackbar } from 'src/app/api/modules/core/components/snackbar/ISnackbar';
import { ISnackbarList } from 'src/app/api/modules/core/components/snackbar/ISnackbarList';
import { ListSnackbarComponent } from '../snackbar/list-snackbar/list-snackbar.component';
import { AppService } from './application/application.service';

/**
 * Gives the ability for the application to create snackbars.
 */
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  /**
   * Constructor
   *
   * @param matSnackBar - a reference to the material snackbar.
   * @param windowService - a reference to the window service.
   */
  constructor(
    private readonly matSnackBar: MatSnackBar,
    private readonly appService: AppService,
  ) {}

  /**
   * Opens a Snackbar
   *
   * @param config - the snackbar configuration.
   */
  open(config: ISnackbar) {
    // if there is no config, that is an error
    if (!config) {
      throw new DigitaServiceError(`[SnackbarService] - Cannot open snackbar as no configuration was provided.`);
    }

    // action data
    let label: string;
    let link: ILink;
    if (config.action?.label) {
      label = config.action.label;
    }
    if (config.action?.link) {
      link = config.action.link;
    }

    // create a snackbar
    const snackbarRef = this.matSnackBar.open(config.message, label, config);

    // redirect data
    let redirect: ILink;
    if (config.redirect) {
      redirect = config.redirect;
    }

    if (link || redirect) {
      // if there is a link listen to its action
      if (link) {
        snackbarRef
          .onAction()
          .pipe(take(1))
          .subscribe(() => {
            this.appService.openLink(link);
          });

        // a redirect occurs instantly
      } else if (redirect) {
        this.appService.openLink(redirect);
      }
    }

    return snackbarRef;
  }

  /**
   * Opens a multi line snackbar.  This is primarily used for Formly File Upload Error.
   *
   * @param config - the snackbar configuration.
   */
  openMultiline(config: ISnackbarList) {
    // if there is no config, that is an error
    if (!config) {
      throw new DigitaServiceError(`[SnackbarService] - Cannot open snackbar as no configuration was provided.`);
    }

    // clone the config and set it as a data property
    const clonedConfig = { ...config, data: config };

    // finally create and return the snackbar reference.
    return this.matSnackBar.openFromComponent<ListSnackbarComponent>(ListSnackbarComponent, clonedConfig);
  }

  /**
   * Dismiss any snackbar currently being shown
   */
  dismiss() {
    this.matSnackBar?.dismiss();
  }
}
