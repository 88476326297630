import { LoggerService } from '@angular-ru/cdk/logger';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DigitaServiceError } from '@digitaservice/utils';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { IBinaryUploadResponse } from 'src/app/api/modules/core/network/IBinaryUploadResponse';
import { IInitializeData } from 'src/app/api/modules/core/network/IInitializeData';
import { IInitializeResponse } from 'src/app/api/modules/core/network/IInitializeResponse';
import { IPollingScheduledResponse } from 'src/app/api/modules/core/network/IPollingScheduleResponse';
import { IRequest } from 'src/app/api/modules/core/network/IRequest';
import { IResponse } from 'src/app/api/modules/core/network/IResponse';
import { IScreenResponse } from 'src/app/api/modules/core/network/IScreenResponse';
import { DIGITASERVICE_API_BACKOFF, DIGITASERVICE_BINARY_BACKOFF, IMPORTANT_API_PATHS } from 'src/app/app-constants';
import { environment } from 'src/environments/environment';
import { AppConfigRepository } from '../configuration/app-config.repository';
import { CredentialRepository } from '../credential/credential.repository';
import { CredentialService } from '../credential/credential.service';
import { SnackbarService } from '../snackbar.service';

/**
 * All API requests and responses are handled through this API Service.
 *
 * 1. Initialze
 * 2. Routes
 * 3. Data
 * 4. Binary
 */
@Injectable({
  providedIn: 'root',
})
export class DigitaServiceAPIService {
  /**
   * The encoding password.
   *
   * See {@link https://github.com/brainfoolong/cryptojs-aes-php/blob/master/dist/example-js.html }
   */
  private readonly enc = '7MXQf2OdyeDvgRw1FOkB7IVy3fCEimjRETsB0f4DjJzDwkh7Was7uKkMPelle5uZwXFnBjmup';

  /**
   * A Reference to the System Wide Configuration Store
   */
  private config$ = this.appConfigRepository.config$;

  /**
   * A Reference to the System Wide Credential Object
   */
  private credential$ = this.credentialRepository.credential$;

  /**
   * Constructor
   */
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly loggerService: LoggerService,
    private readonly http: HttpClient,
    private readonly appConfigRepository: AppConfigRepository,
    private readonly credentialRepository: CredentialRepository,
    private readonly credentialService: CredentialService,
  ) {}

  /**
   * The initialize is a Special Request in the system.
   *
   * It is the first request made to the API and
   * has not yet been assigned a credential. The server will gather
   * the data being send and form a `credential` for the user.
   *
   * @param data - the object containing data collected from the environent,
   *               url-parameters and window.DigitaService inputs.
   */
  initialize(data: IInitializeData) {
    this.loggerService.group('[DigitaServiceAPIService::initialize]');
    this.loggerService.log(data);
    this.loggerService.close();
    return this.createStandardAPIRequest<IInitializeResponse>(IMPORTANT_API_PATHS.INITIALIZE, data).pipe(
      map(this.processStandardResponse()),
    );
  }

  /**
   * Adds a Form Data as Binary. These types of calls (File Uploads) are
   * not suitable for our standard JSON based networking service. As a result
   * of that, it differs.
   *
   * This attempts to attach the credential to the form data before sending it.
   *
   * Note that the response unlike other network calls is not a `IResponse` and therefor cannot update the credential.
   *
   * @param relativePath - the url to send the form data to
   * @param formData - the formData itself
   */
  binary<T extends IBinaryUploadResponse>(relativePath: string, formData: FormData): Observable<HttpEvent<T>> {
    // before making a request with the raw binary, lets try to attach the credential
    return this.credential$.pipe(
      // with the credential
      switchMap((credential) => {
        // try to stringify the credential so it can be appended to the form data
        let credentialString = '';
        if (credential && formData) {
          try {
            credentialString = JSON.stringify(credential);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (e) {
            // it failed to stringify the credential
          }
        }
        // attach the credential
        formData.append('credential', credentialString);
        // return the binary request
        return this.createBinaryAPIRequest<T>(relativePath, formData);
      }),
    );
  }

  /**
   * Data Requests are not involved with route content. These are calls
   * to the API not dealing with routes.
   *
   * @param relativePath - the API end point path such as 'initiailize', NOT the full URL
   * @param data - the item we want to send as a POST to the API. This object will be attached to the `data` property of the request
   * @param encrypt - data can be encrpyted before being sent
   */
  data<T extends IResponse>(relativePath: string, data?: object, encrypt?: boolean): Observable<T> {
    let finalData: Observable<T>;
    if (encrypt) {
      const packagedData = this.package(data);
      finalData = this.createStandardAPIRequest<T>(relativePath, packagedData);
    } else {
      finalData = this.createStandardAPIRequest<T>(relativePath, data);
    }
    return finalData.pipe(map(this.processStandardResponse()));
  }

  /**
   * Scheduled Data Requests are not involved with route content. These are calls
   * to the API not dealing with routes and are used with polling based systems.
   *
   * @param scheduleTimeMS - the milisecond timer which is the amount of time required to pass before the relative path is requested.
   * @param relativePath - the API end point path such as 'initiailize', NOT the full URL
   * @param data - the item we want to send as a POST to the API. This object will be attached to the `data` property of the request
   * @param encrypt - data can be encrpyted before being sent
   */
  schedule<T extends IPollingScheduledResponse>(
    scheduleTimeMS: number,
    relativePath: string,
    data?: object,
    encrypt?: boolean,
  ): Observable<T> {
    return timer(scheduleTimeMS).pipe(
      take(1),
      switchMap(() => {
        return this.data<T>(relativePath, data, encrypt);
      }),
    );
  }

  /**
   * Route Requests are used for all navigation routes and as a result are used by the digitaservice-route-resolver-service only
   *
   * @param relativePath - short form route path
   * @param data - any data to be sent
   */
  route<T extends IScreenResponse>(relativePath: string, data?: object): Observable<T> {
    return this.createStandardAPIRequest<T>(relativePath, data).pipe(map(this.processStandardResponse()));
  }

  ////////////////////////////////////////////////////////////////////////////////
  // RESPONSES
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * All DigitaService HTTP Responses implement {@link IResponse} and contain
   * optional data:
   *
   * 1. The Server may send me to an Error Route for any Server Reason. Errors
   * take priority in the response. So an Error with a Snackbar never runs the snackbar.
   * 2. Should the server create a snackbar, it will be shown.
   * 3. Should the server contain a credential, then it should be stored.
   */
  private processStandardResponse<T extends IResponse>(): (response: T) => T {
    return (response) => {
      // if there is an error we handle it explicitly
      if (response.error) {
        throw new DigitaServiceError(response.error?.message, response.error?.image);
      } else {
        // always show snackbars
        if (response.snackbar) {
          this.snackbarService.open(response.snackbar);
        }

        // always save the credential
        if (response.credential) {
          this.credentialService.applyCredential(response.credential);
        }
      }
      return response;
    };
  }

  ////////////////////////////////////////////////////////////////////////////////
  // ENCRYPTION
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Packages (ecrypts) the data object.
   *
   * See https://github.com/brainfoolong/cryptojs-aes-php/blob/master/dist/example-js.html
   *
   * @param data - the object to package
   */
  private package(data: unknown): string {
    if (data) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.enc, { format: CryptoJSAesJson }).toString();
    }
    return '';
  }

  ////////////////////////////////////////////////////////////////////////////////
  // REQUESTS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * All {@link IRequest} and {@link IResponse} throughout the system is dealt with here. It
   * is the main data API.
   *
   * @param relativePath a relative api path starting with a `/` prefix. Such as `/a/b`
   * @param data a data object which is the main payload of the request.
   */
  private createStandardAPIRequest<T>(relativePath: string, data: string | object): Observable<T> {
    // first start with getting the credential
    return this.credential$.pipe(
      // we only need to do this once
      first(),
      // with the credential
      switchMap((credential) => {
        // the payload always contains the data object
        const payload: IRequest = {
          data,
        };
        // add the latest credential if it exists
        if (credential) {
          payload.credential = credential;
        }

        // now go and get the configuration
        return this.config$.pipe(
          // we only need to do this once
          first(),
          // with the configuration
          switchMap((configuration) => {
            // construct the absolute path
            const absolutePath = `${configuration.apiroot}${relativePath}`;

            // convert the payload to a transmission string
            const transmission = JSON.stringify(payload);

            // finally make the post request
            return this.http
              .post<T>(absolutePath, transmission, {
                withCredentials: environment.useWithCredentials,
              })
              .pipe(
                // if there is an error, we will retry
                retryBackoff({
                  initialInterval: DIGITASERVICE_API_BACKOFF.EXPO,
                  maxInterval: DIGITASERVICE_API_BACKOFF.MAX_INTERVAL,
                  maxRetries: DIGITASERVICE_API_BACKOFF.MAX_RETRIES,
                  shouldRetry: () => {
                    return true;
                  },
                }),
                // if there is an error, we will catch it and make it nicer
                catchError((error: HttpErrorResponse) => {
                  // handle unknow errors by default
                  let errorString = `Unknown Error`;

                  // if the error status is 0, it is a client problem
                  if (error.status === 0) {
                    errorString = `There was a problem with the client request. ${error.message}`;
                  } else {
                    errorString = `There was a problem with the server request. ${error.message}`;
                  }

                  // throw a new error
                  return throwError(() => new DigitaServiceError(errorString));
                }),
              );
          }),
        );
      }),
    );
  }

  /**
   * Sending binary files do not belong inside the standard JSON API and so uploading
   * files has it's own mechanism.
   *
   * @param relativePath a relative api path starting with a `/` prefix. Such as `/a/b`
   * @param formData a data object which is the main payload of the request.
   */
  private createBinaryAPIRequest<T>(relativePath: string, formData: FormData): Observable<HttpEvent<T>> {
    // now go and get the configuration
    return this.config$.pipe(
      // we only need to do this once
      first(),
      // with the configuration
      switchMap((configuration) => {
        // construct the absolute path
        const absolutePath = `${configuration.apiroot}${relativePath}`;

        // finally make the post request
        return this.http
          .request<T>(
            new HttpRequest('POST', absolutePath, formData, {
              reportProgress: true,
              withCredentials: environment.useWithCredentials,
            }),
          )
          .pipe(
            // if there is an error, we will retry
            retryBackoff({
              initialInterval: DIGITASERVICE_BINARY_BACKOFF.EXPO,
              maxInterval: DIGITASERVICE_BINARY_BACKOFF.MAX_INTERVAL,
              maxRetries: DIGITASERVICE_BINARY_BACKOFF.MAX_RETRIES,
              shouldRetry: () => {
                return true;
              },
            }),
            // if there is an error, we will catch it and make it nicer
            catchError((error: HttpErrorResponse) => {
              // handle unknow errors by default
              let errorString = `Unknown Error`;

              // if the error status is 0, it is a client problem
              if (error.status === 0) {
                errorString = `There was a problem with the client request. ${error.message}`;
              } else {
                errorString = `There was a problem with the server request. ${error.message}`;
              }

              // throw a new error
              return throwError(() => new DigitaServiceError(errorString));
            }),
          );
      }),
    );
  }
}
