import { Injectable } from '@angular/core';
import { CredentialRepository } from './credential.repository';

/**
 * The CredentialService is used to publically update the {@link CredentialRepository}.
 *
 * This object is controlled by the server. By design the front end doesn't touch it, inspect it, or modify it.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialService {
  /**
   * Constructor
   */
  constructor(private readonly credentialRepository: CredentialRepository) {}

  /**
   * Updates the server credential.
   */
  applyCredential(credential: unknown) {
    this.credentialRepository.applyCredential(credential);
  }
}
