import * as i0 from '@angular/core';
import { INJECTOR, Injectable, Inject, InjectionToken, NgModule, Self } from '@angular/core';
import { isNil, isNotNil } from '@angular-ru/cdk/utils';
import { __decorate } from 'tslib';
import { BoundClass } from '@angular-ru/cdk/decorators';
import { isString } from '@angular-ru/cdk/string';
class LoggerInjector {
  static {
    this.injector = null;
  }
  constructor(injector) {
    LoggerInjector.injector = injector;
  }
  static getInjector() {
    if (isNil(LoggerInjector.injector)) {
      throw new Error("You've forgotten to import `LoggerModule`");
    }
    return LoggerInjector.injector;
  }
  static {
    this.ɵfac = function LoggerInjector_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoggerInjector)(i0.ɵɵinject(INJECTOR));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoggerInjector,
      factory: LoggerInjector.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerInjector, [{
    type: Injectable
  }], () => [{
    type: i0.Injector,
    decorators: [{
      type: Inject,
      args: [INJECTOR]
    }]
  }], null);
})();
const LOGGER_OPTIONS = new InjectionToken('LOGGER_OPTIONS');
/**
 * Used in factory methods
 */
// eslint-disable-next-line no-restricted-syntax
var LoggerLevel;
(function (LoggerLevel) {
  LoggerLevel[LoggerLevel["ALL"] = 1] = "ALL";
  LoggerLevel[LoggerLevel["TRACE"] = 2] = "TRACE";
  LoggerLevel[LoggerLevel["DEBUG"] = 3] = "DEBUG";
  LoggerLevel[LoggerLevel["INFO"] = 4] = "INFO";
  LoggerLevel[LoggerLevel["LOG"] = 5] = "LOG";
  LoggerLevel[LoggerLevel["WARN"] = 6] = "WARN";
  LoggerLevel[LoggerLevel["ERROR"] = 7] = "ERROR";
  LoggerLevel[LoggerLevel["OFF"] = 8] = "OFF";
})(LoggerLevel || (LoggerLevel = {}));
var GroupLevel;
(function (GroupLevel) {
  GroupLevel["GROUP"] = "group";
  GroupLevel["GROUP_COLLAPSED"] = "groupCollapsed";
})(GroupLevel || (GroupLevel = {}));
class LoggerOptionsImpl {
  constructor() {
    this.instance = console;
    this.minLevel = LoggerLevel.ALL;
    this.globalLineStyle = '';
    this.cssClassMap = {};
    this.useLevelGroup = true;
    this.labelColors = {
      [LoggerLevel.TRACE]: "#000080" /* COLORS.TRACE */,
      [LoggerLevel.DEBUG]: "#00BFFE" /* COLORS.DEBUG */,
      [LoggerLevel.INFO]: "#000000" /* COLORS.INFO */,
      [LoggerLevel.WARN]: "#FF6419" /* COLORS.WARN */,
      [LoggerLevel.ERROR]: "#F1062D" /* COLORS.ERROR */
    };
    this.labelNames = {
      [LoggerLevel.TRACE]: "TRACE" /* LABELS.TRACE */,
      [LoggerLevel.DEBUG]: "DEBUG" /* LABELS.DEBUG */,
      [LoggerLevel.INFO]: "INFO" /* LABELS.INFO */,
      [LoggerLevel.WARN]: "WARN" /* LABELS.WARN */,
      [LoggerLevel.ERROR]: "ERROR" /* LABELS.ERROR */
    };
  }
  format(label, style) {
    return {
      label: `[${label}]:`,
      style
    };
  }
  static {
    this.ɵfac = function LoggerOptionsImpl_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoggerOptionsImpl)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoggerOptionsImpl,
      factory: LoggerOptionsImpl.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerOptionsImpl, [{
    type: Injectable
  }], null, null);
})();
class ConsoleService {
  constructor(options) {
    this.options = options;
    this.minLevel = options.minLevel;
    this.instance = options.instance;
  }
  get noop() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }
  get console() {
    return this.instance;
  }
  set console(instance) {
    this.instance = instance;
  }
  getTemplateLabel(text) {
    return `%c${text}`;
  }
  getFormatTemplateLabel(text) {
    return `%c${text} %c%s`;
  }
  getTemplateWithoutLabel() {
    return '%c%s';
  }
  static {
    this.ɵfac = function ConsoleService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConsoleService)(i0.ɵɵinject(LOGGER_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConsoleService,
      factory: ConsoleService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConsoleService, [{
    type: Injectable
  }], () => [{
    type: LoggerOptionsImpl,
    decorators: [{
      type: Inject,
      args: [LOGGER_OPTIONS]
    }]
  }], null);
})();
class CssFactory {
  constructor(options) {
    this.options = options;
    this.lineStyle = null;
  }
  get style() {
    const style = this.localStyle;
    this.clearLocalStyle();
    return `${this.globalStyles}${style}`;
  }
  set style(css) {
    this.lineStyle = css;
  }
  get globalStyles() {
    return this.options.globalLineStyle ? `${this.options.globalLineStyle};` : '';
  }
  get localStyle() {
    return (this.lineStyle?.length ?? 0) > 0 ? `${this.lineStyle};` : '';
  }
  getStyleLabel(level) {
    const color = this.options.labelColors[level];
    return `color: ${color}; font-weight: bold`;
  }
  setClass(cssClassName) {
    const classList = cssClassName.split(/\s+/g);
    const styles = [];
    for (const className of classList) {
      const style = this.options.cssClassMap[className];
      if (isNotNil(style)) {
        styles.push(style);
      }
    }
    const localStyles = styles.length > 0 ? styles.join('; ') : '';
    this.lineStyle = `${this.globalStyles}${localStyles}`;
  }
  clearLocalStyle() {
    this.lineStyle = '';
  }
  static {
    this.ɵfac = function CssFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CssFactory)(i0.ɵɵinject(LOGGER_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CssFactory,
      factory: CssFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CssFactory, [{
    type: Injectable
  }], () => [{
    type: LoggerOptionsImpl,
    decorators: [{
      type: Inject,
      args: [LOGGER_OPTIONS]
    }]
  }], null);
})();

// eslint-disable-next-line @typescript-eslint/naming-convention
var LABELS;
(function (LABELS) {
  LABELS["TRACE"] = "TRACE";
  LABELS["DEBUG"] = "DEBUG";
  LABELS["INFO"] = "INFO";
  LABELS["WARN"] = "WARN";
  LABELS["ERROR"] = "ERROR";
})(LABELS || (LABELS = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
var COLORS;
(function (COLORS) {
  COLORS["TRACE"] = "#000080";
  COLORS["DEBUG"] = "#00BFFE";
  COLORS["INFO"] = "#000000";
  COLORS["WARN"] = "#FF6419";
  COLORS["ERROR"] = "#F1062D";
})(COLORS || (COLORS = {}));
const DEFAULT_METHODS = {
  /**
   * Used `debug` instead `trace` method because need
   * output without stack trace in console
   * LoggerLevel.TRACE -> console.debug
   */
  [LoggerLevel.TRACE]: 'debug',
  [LoggerLevel.LOG]: 'log',
  [LoggerLevel.DEBUG]: 'info',
  [LoggerLevel.INFO]: 'info',
  [LoggerLevel.WARN]: 'warn',
  [LoggerLevel.ERROR]: 'error'
};
const LEXER_JSON = /("(\\u[\dA-Za-z]{4}|\\[^u]|[^"\\])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[Ee][+\-]?\d+)?)/g;
class GroupFactory {
  constructor(console, cssFactory, options) {
    this.console = console;
    this.cssFactory = cssFactory;
    this.options = options;
    this.counterOpenedGroup = 0;
    this.executePipesGroup = false;
  }
  close() {
    if (this.executePipesGroup) {
      this.counterOpenedGroup--;
      this.console.instance.groupEnd();
    }
  }
  closeAll() {
    while (this.counterOpenedGroup > 0) {
      this.close();
    }
  }
  group(title, pipeline, logger, level) {
    const group = this.console.instance.group.bind(this.console.instance);
    return this.createGroupLogger(group, title, pipeline, logger, level);
  }
  groupCollapsed(title, pipeline, logger, level) {
    const groupCollapsed = this.console.instance.groupCollapsed.bind(this.console.instance);
    return this.createGroupLogger(groupCollapsed, title, pipeline, logger, level);
  }
  createGroupLogger(groupType, title, pipeline, logger, level) {
    const showGroup = this.console.minLevel <= level;
    let pipeLineResult;
    if (showGroup) {
      this.executePipesGroup = true;
      this.counterOpenedGroup++;
      const lineStyle = this.cssFactory.getStyleLabel(level);
      const {
        label: formatLabel,
        style: formatStyle
      } = this.getLabel(LoggerLevel[level], lineStyle);
      groupType(`%c${formatLabel}`, formatStyle, title);
      if (isNotNil(pipeline)) {
        const result = pipeline(logger);
        this.close();
        pipeLineResult = result;
      }
    } else {
      this.executePipesGroup = false;
    }
    return pipeLineResult;
  }
  getLabel(level, style) {
    return this.options.format(level, style);
  }
  static {
    this.ɵfac = function GroupFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GroupFactory)(i0.ɵɵinject(ConsoleService), i0.ɵɵinject(CssFactory), i0.ɵɵinject(LOGGER_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GroupFactory,
      factory: GroupFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupFactory, [{
    type: Injectable
  }], () => [{
    type: ConsoleService,
    decorators: [{
      type: Inject,
      args: [ConsoleService]
    }]
  }, {
    type: CssFactory,
    decorators: [{
      type: Inject,
      args: [CssFactory]
    }]
  }, {
    type: LoggerOptionsImpl,
    decorators: [{
      type: Inject,
      args: [LOGGER_OPTIONS]
    }]
  }], null);
})();
class LoggerFactory {
  // eslint-disable-next-line max-params
  constructor(options, console, cssFactory, groupFactory) {
    this.options = options;
    this.console = console;
    this.cssFactory = cssFactory;
    this.groupFactory = groupFactory;
  }
  createLogger(level, logger) {
    const args = this.getArgumentsByType(level);
    const methodName = DEFAULT_METHODS[level];
    const operation = this.console.minLevel <= level ? this.console.instance[methodName].bind(...args) : this.console.noop;
    const pipeOperation = this.options.useLevelGroup ? this.defineLevelGroups(level, operation, logger) : operation;
    return pipeOperation;
  }
  defineLevelGroups(level, operation, logger) {
    Object.defineProperties(operation, {
      ["group" /* GroupLevel.GROUP */]: this.setGroupMethod("group" /* GroupLevel.GROUP */, level, logger),
      ["groupCollapsed" /* GroupLevel.GROUP_COLLAPSED */]: this.setGroupMethod("groupCollapsed" /* GroupLevel.GROUP_COLLAPSED */, level, logger)
    });
    return operation;
  }
  setGroupMethod(methodName, level, logger) {
    return {
      enumerable: true,
      configurable: true,
      value: (label, pipeLine) => {
        const groupMethod = this.groupFactory[methodName].bind(this.groupFactory);
        groupMethod(label, pipeLine, logger, level);
        return logger;
      }
    };
  }
  // eslint-disable-next-line max-lines-per-function
  getArgumentsByType(level) {
    const styleLabel = this.cssFactory.getStyleLabel(level);
    const lineStyle = this.cssFactory.style;
    const args = [this.console.instance];
    const withLabel = level !== LoggerLevel.LOG;
    if (withLabel) {
      const {
        label: formatLabel,
        style
      } = this.options.format(this.options.labelNames[level], styleLabel);
      if (lineStyle) {
        const label = this.console.getFormatTemplateLabel(formatLabel);
        args.push(label, style, lineStyle);
      } else {
        const label = this.console.getTemplateLabel(formatLabel);
        args.push(label, style);
      }
    } else if (lineStyle) {
      args.push(this.console.getTemplateWithoutLabel(), lineStyle);
    }
    return args;
  }
  static {
    this.ɵfac = function LoggerFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoggerFactory)(i0.ɵɵinject(LOGGER_OPTIONS), i0.ɵɵinject(ConsoleService), i0.ɵɵinject(CssFactory), i0.ɵɵinject(GroupFactory));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoggerFactory,
      factory: LoggerFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerFactory, [{
    type: Injectable
  }], () => [{
    type: LoggerOptionsImpl,
    decorators: [{
      type: Inject,
      args: [LOGGER_OPTIONS]
    }]
  }, {
    type: ConsoleService,
    decorators: [{
      type: Inject,
      args: [ConsoleService]
    }]
  }, {
    type: CssFactory,
    decorators: [{
      type: Inject,
      args: [CssFactory]
    }]
  }, {
    type: GroupFactory,
    decorators: [{
      type: Inject,
      args: [GroupFactory]
    }]
  }], null);
})();
class JsonFactory {
  constructor() {
    this._string = 'color:green';
    this._number = 'color:darkorange';
    this._boolean = 'color:blue';
    this._null = 'color:magenta';
    this._key = 'color:red';
    this.lexerTypeFinder = LEXER_JSON;
  }
  // eslint-disable-next-line max-lines-per-function
  colorsJSON(json) {
    let resultJson = json;
    const arr = [];
    resultJson = resultJson.replace(this.lexerTypeFinder, match => {
      let style = this._number;
      if (match.startsWith('"')) {
        if (match.endsWith(':')) {
          style = this._key;
        } else {
          style = this._string;
        }
      } else if (/true|false/.test(match)) {
        style = this._boolean;
      } else if (match.includes('null')) {
        style = this._null;
      }
      arr.push(style, '');
      return `%c${match}%c`;
    });
    arr.unshift(resultJson);
    return arr;
  }
  static {
    this.ɵfac = function JsonFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JsonFactory)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JsonFactory,
      factory: JsonFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonFactory, [{
    type: Injectable
  }], null, null);
})();
class TimerFactory {
  constructor(console) {
    this.console = console;
    this.DIGITS_TO_FIX = 4;
    this.SECONDS = 1000;
  }
  startTime(title, level) {
    let result = null;
    // eslint-disable-next-line sonarjs/no-inverted-boolean-check
    const canExecute = !(this.console.minLevel > level);
    if (canExecute) {
      result = {
        startTime: performance.now(),
        title
      };
    }
    return result;
  }
  endTime(info, level, isMillisecond, logger) {
    // eslint-disable-next-line sonarjs/no-inverted-boolean-check
    const canExecute = !(this.console.minLevel > level);
    if (canExecute) {
      const methodName = DEFAULT_METHODS[level];
      const time = this.ensureTime(info, isMillisecond);
      const logMethod = logger[methodName];
      logMethod(`TimerLog: ${info.title}`, `took ${time} to execute`);
    }
  }
  ensureTime(info, isMillisecond) {
    const msTime = parseFloat((performance.now() - info.startTime).toFixed(this.DIGITS_TO_FIX));
    return isMillisecond ? `${msTime}ms` : `${Math.floor(msTime / this.SECONDS)}s`;
  }
  static {
    this.ɵfac = function TimerFactory_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TimerFactory)(i0.ɵɵinject(ConsoleService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TimerFactory,
      factory: TimerFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimerFactory, [{
    type: Injectable
  }], () => [{
    type: ConsoleService,
    decorators: [{
      type: Inject,
      args: [ConsoleService]
    }]
  }], null);
})();
let LoggerService = class LoggerService {
  // eslint-disable-next-line max-params
  constructor(cssFactory, console, factory, groupFactory, jsonFactory, timerFactory, options) {
    this.cssFactory = cssFactory;
    this.console = console;
    this.factory = factory;
    this.groupFactory = groupFactory;
    this.jsonFactory = jsonFactory;
    this.timerFactory = timerFactory;
    this.options = options;
    this.DEFAULT_DEPTH = 2;
  }
  get clear() {
    return this.console.instance.clear.bind(this.console.instance);
  }
  get table() {
    return this.console.instance.table.bind(this.console.instance);
  }
  get log() {
    return this.factory.createLogger(LoggerLevel.LOG, this);
  }
  get trace() {
    return this.factory.createLogger(LoggerLevel.TRACE, this);
  }
  get assert() {
    return this.console.instance.assert.bind(this.console.instance);
  }
  get debug() {
    return this.factory.createLogger(LoggerLevel.DEBUG, this);
  }
  get info() {
    return this.factory.createLogger(LoggerLevel.INFO, this);
  }
  get warn() {
    return this.factory.createLogger(LoggerLevel.WARN, this);
  }
  get error() {
    return this.factory.createLogger(LoggerLevel.ERROR, this);
  }
  get level() {
    return this.console.minLevel;
  }
  set level(level) {
    this.console.minLevel = level;
  }
  getCurrentLineStyle() {
    return this.cssFactory.style;
  }
  clearCssCurrentLine() {
    this.cssFactory.style = '';
  }
  setLabels(labels) {
    this.options.labelNames = {
      ...this.options.labelNames,
      ...labels
    };
  }
  setColors(colors) {
    this.options.labelColors = {
      ...this.options.labelColors,
      ...colors
    };
  }
  pipe(...pipelines) {
    if (this.groupFactory.executePipesGroup) {
      for (const pipeline of pipelines) {
        pipeline(this);
      }
    }
    return this;
  }
  groupCollapsed(title, pipeline) {
    this.groupFactory.groupCollapsed(title, pipeline, this, LoggerLevel.INFO);
    return this;
  }
  close() {
    this.groupFactory.close();
    return this;
  }
  closeAll() {
    this.groupFactory.closeAll();
    return this;
  }
  group(title, pipeline) {
    this.groupFactory.group(title, pipeline, this, LoggerLevel.INFO);
    return this;
  }
  css(style) {
    this.cssFactory.style = style;
    return this;
  }
  prettyJSON(json) {
    return this.jsonFactory.colorsJSON(JSON.stringify(json, null, this.DEFAULT_DEPTH));
  }
  cssClass(cssClassName) {
    this.cssFactory.setClass(cssClassName);
    return this;
  }
  startTime(title, level = LoggerLevel.DEBUG) {
    return this.timerFactory.startTime(title, level);
  }
  endTime(info, level = LoggerLevel.DEBUG, isMillisecond = true) {
    if (isNotNil(info)) {
      this.timerFactory.endTime(info, level, isMillisecond, this);
    }
  }
  static {
    this.ɵfac = function LoggerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoggerService)(i0.ɵɵinject(CssFactory), i0.ɵɵinject(ConsoleService), i0.ɵɵinject(LoggerFactory), i0.ɵɵinject(GroupFactory), i0.ɵɵinject(JsonFactory), i0.ɵɵinject(TimerFactory), i0.ɵɵinject(LOGGER_OPTIONS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoggerService,
      factory: LoggerService.ɵfac
    });
  }
};
LoggerService = __decorate([BoundClass], LoggerService);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerService, [{
    type: Injectable
  }], () => [{
    type: CssFactory,
    decorators: [{
      type: Inject,
      args: [CssFactory]
    }]
  }, {
    type: ConsoleService,
    decorators: [{
      type: Inject,
      args: [ConsoleService]
    }]
  }, {
    type: LoggerFactory,
    decorators: [{
      type: Inject,
      args: [LoggerFactory]
    }]
  }, {
    type: GroupFactory,
    decorators: [{
      type: Inject,
      args: [GroupFactory]
    }]
  }, {
    type: JsonFactory,
    decorators: [{
      type: Inject,
      args: [JsonFactory]
    }]
  }, {
    type: TimerFactory,
    decorators: [{
      type: Inject,
      args: [TimerFactory]
    }]
  }, {
    type: LoggerOptionsImpl,
    decorators: [{
      type: Inject,
      args: [LOGGER_OPTIONS]
    }]
  }], null);
})();
function DebugLog() {
  return function (target, propertyName) {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).debug;
      }
    });
  };
}
function ErrorLog() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).error;
      }
    });
  };
}
function groupDecoratorFactory(level, groupType, method, title, args, target) {
  const logger = LoggerInjector.getInjector().get(LoggerService);
  const groupFactory = LoggerInjector.getInjector().get(GroupFactory);
  const groupMethod = groupFactory[groupType].bind(groupFactory);
  const label = isString(title) ? title : title(...args);
  groupMethod(label, null, logger, level);
  const result = method.apply(target, args);
  if (logger.level <= level) {
    logger.close();
  }
  return result;
}
function Group(title, level = LoggerLevel.INFO) {
  return function (_target, _key, descriptor) {
    const method = descriptor.value;
    descriptor.value = function (...args) {
      return groupDecoratorFactory(level, "group" /* GroupLevel.GROUP */, method, title, args, this);
    };
    return descriptor;
  };
}
function GroupCollapsed(title, level = LoggerLevel.INFO) {
  return function (_target, _key, descriptor) {
    const method = descriptor.value;
    descriptor.value = function (...args) {
      return groupDecoratorFactory(level, "groupCollapsed" /* GroupLevel.GROUP_COLLAPSED */, method, title, args, this);
    };
    return descriptor;
  };
}
function InfoLog() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).info;
      }
    });
  };
}
function Log() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).log;
      }
    });
  };
}
function Logger() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService);
      }
    });
  };
}

// eslint-disable-next-line max-lines-per-function
function TimerLog(title, level = LoggerLevel.DEBUG, isMillisecond = true) {
  return function (_target, _key, descriptor) {
    let result;
    const method = descriptor.value;
    descriptor.value = function (...args) {
      const info = LoggerInjector.getInjector().get(LoggerService).startTime(title, level);
      result = method.apply(this, args);
      LoggerInjector.getInjector().get(LoggerService).endTime(info, level, isMillisecond);
      return result;
    };
    return descriptor;
  };
}
function TraceLog() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).trace;
      }
    });
  };
}
function WarnLog() {
  return (target, propertyName) => {
    Object.defineProperty(target, propertyName, {
      configurable: false,
      get() {
        return LoggerInjector.getInjector().get(LoggerService).warn;
      }
    });
  };
}
class LoggerModule {
  static {
    this.ROOT_OPTIONS = new InjectionToken('ROOT_OPTIONS');
  }
  constructor(loggerInjector) {
    this.loggerInjector = loggerInjector;
  }
  static forRoot(config = {}) {
    return {
      ngModule: LoggerModule,
      providers: [{
        provide: LoggerModule.ROOT_OPTIONS,
        useValue: config
      }, {
        provide: LOGGER_OPTIONS,
        useFactory: LoggerModule.loggerConfigFactory,
        deps: [LoggerModule.ROOT_OPTIONS]
      }]
    };
  }
  static loggerConfigFactory(config) {
    return Object.assign(new LoggerOptionsImpl(), config);
  }
  static {
    this.ɵfac = function LoggerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoggerModule)(i0.ɵɵinject(LoggerInjector, 2));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LoggerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ConsoleService, CssFactory, GroupFactory, JsonFactory, LoggerFactory, LoggerInjector, LoggerService, TimerFactory]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerModule, [{
    type: NgModule,
    args: [{
      providers: [ConsoleService, CssFactory, GroupFactory, JsonFactory, LoggerFactory, LoggerInjector, LoggerService, TimerFactory]
    }]
  }], () => [{
    type: LoggerInjector,
    decorators: [{
      type: Self
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { COLORS, ConsoleService, DEFAULT_METHODS, DebugLog, ErrorLog, Group, GroupCollapsed, GroupLevel, InfoLog, LABELS, LEXER_JSON, LOGGER_OPTIONS, Log, Logger, LoggerInjector, LoggerLevel, LoggerModule, LoggerService, TimerLog, TraceLog, WarnLog };
